<template>
  <div>

    <!-- Row Loop -->
    <validation-observer ref="formObserver">
      <b-form ref="form" class="repeater-form">
        <b-overlay
            variant="white"
            :show="creating"
            spinner-variant="primary"
            blur="1"
            opacity=".75"
            rounded="sm"
        >
          <b-row ref="row">
            <!-- Title -->
            <b-col md="12">
              <b-button
                  :variant="isSubItem? 'secondary':'primary'"
                  class="mb-1 mr-1 itemCodeRef">
                Ref. {{ value.item_reference_code }}
              </b-button>
              <span v-if="isSubItem">Sub-Item</span>
            </b-col>


            <!-- Product Category -->
            <b-col md="12">
              <b-row>
                <b-col md="4">
                  <b-form-group :label="`Product Category`" :label-for="`product-category-${offer_elevation}-${refIndex}`">
                    <validation-provider #default="{ errors }"
                                         :name="`product category ${offer_elevation} item`" rules="required">
                      <v-select
                          :id="`product-category-${offer_elevation}-${refIndex}`" class="custom-select-style"
                          :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                          :loading="categoryLoader" v-model="value.category_id"
                          :clearable="false"
                          @input="selectProduct($event,true)" :options="categoryOptions"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Select Category"
                          :reduce="option => option.id" label="name"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Item Name -->
                <b-col md="4">
                  <b-form-group :label="`Item Name`" label-for="item-name">
                    <validation-provider #default="{ errors }" :name="`item name ${offer_elevation} item`"
                                         rules="required">
                      <v-select id="item-name" class="custom-select-style" v-model="value.item_id"
                                :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                                @input="selectItem($event, true)"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="value.itemOptions"
                                placeholder="Select Item" :reduce="option => option.id" label="name"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Description -->
                <b-col md="4">
                  <b-form-group :label="`Description Name`" label-for="description-name">
                    <validation-provider #default="{ errors }"
                                         :name="`description name ${offer_elevation} item`" rules="required">
                      <v-select id="description-name" class="custom-select-style"
                                v-model="value.description_id"
                                :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                                :clearable="false"
                                @input="selectDescription"
                                :loading="descriptionLoader" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="value.descriptionOptions" placeholder="Select Description"
                                :reduce="option => option.description_id" label="content"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="12">
              <b-row>
                <!-- Glass Type -->
                <b-col md="4">
                  <b-form-group
                      v-if="!value.withProductCategoryNote"
                      label-for="glass-type">
                    <template #label>
                      <span>Glass Type </span>
                      <feather-icon
                          v-if="value.withCustomGlassTypeSelect"
                          v-b-tooltip.hover="'You can add other glass type by selecting the text you typed'"
                          @click.prevent
                          icon="InfoIcon"
                      />
                    </template>
                    <validation-provider #default="{ errors }" :name="`glass type ${offer_elevation} item`"
                                         rules="required">
                      <v-select
                          id="glass-type"
                          class="custom-select-style"
                          :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                          :loading="glassTypeLoader"
                          v-model="value.glass_type_id"
                          :options="value.localGlassTypeOptions"
                          :clearable="false"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Select Glass Type"
                          :reduce="option => option.id"
                          :taggable="value.withCustomGlassTypeSelect!==0"
                          multiple
                          :create-option="tag => ({id:tag, name: tag })"
                          @input="limitSelected($event, 'glass_type_id')"
                          label="name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                      v-if="value.withProductCategoryNote"
                      :label="`Notes`"
                      label-for="Notes">
                    <b-form-input
                        id="Notes"
                        v-model="value.product_category_note"
                        :debounce="1000"
                        @update="mapAutoSaveData($event,'product_category_note')"
                        placeholder="Notes"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-row>
                    <!-- Width -->
                    <b-col md="6">
                      <b-form-group :label="`Width (mm)`" :label-for="`Width-${offer_elevation}-${refIndex}`">
                        <template #label>
                          <span>Width</span>
                          <feather-icon
                              v-b-tooltip.hover="'Width is disabled while \'SQM\' input is filled '"
                              icon="InfoIcon"
                          />
                        </template>
                        <validation-provider #default="{ errors }" :name="`width ${offer_elevation} item`"
                                             :rules="value.SQM > 0 ? '':'required'">
                          <b-form-input
                              :id="`Width-${offer_elevation}-${refIndex}`"
                              type="number"
                              :state="errors.length > 0 || value.widthError ? false : null"
                              @input="minValue($event,'widthError')"
                              @wheel.prevent
                              @keydown="onlyNumbers"
                              placeholder="1"
                              :disabled="value.SQM > 0"
                              :debounce="1000"
                              @update="mapAutoSaveData($event = $event === '' ? null :$event,'width')"
                              v-model="value.width"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small v-if="!errors[0]" class="text-danger">{{ value.widthError }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Height -->
                    <b-col md="6">
                      <b-form-group :label="`Height (mm)`" :label-for="`Height-${offer_elevation}-${refIndex}`">
                        <template #label>
                          <span>Height</span>
                          <feather-icon
                              v-b-tooltip.hover="'Height is disabled while \'SQM\' input is filled '"
                              icon="InfoIcon"
                          />
                        </template>
                        <validation-provider #default="{ errors }" :name="`height ${offer_elevation} item`"
                                             :rules="value.SQM > 0 ? '':'required'">
                          <b-form-input
                              :id="`Height-${offer_elevation}-${refIndex}`" type="number"
                              @keydown="onlyNumbers"
                              @wheel.prevent
                              @input="minValue($event,'heightError')"
                              :state="errors.length > 0 || value.heightError ? false : null" placeholder="1"
                              :disabled="value.SQM > 0"
                              :debounce="1000"
                              @update="mapAutoSaveData($event = $event === '' ? null :$event,'height')"
                              v-model="value.height"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small v-if="!errors[0]" class="text-danger">{{ value.heightError }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- GlassDetails -->
                <b-col v-if="value.withGlassDetails" md="2">
                  <b-form-group label-for="additional-options">
                    <template #label>
                      <span>Glass Details </span>
                      <feather-icon
                          v-b-tooltip.hover="'You can add other glass details by selecting the text you typed'"
                          icon="InfoIcon"
                      />
                    </template>
                    <validation-provider #default="{ errors }"
                                         :name="`glass details ${offer_elevation} item`" rules="required">
                      <v-select id="glass-details" class="custom-select-style"
                                v-model="value.glass_detail_id"
                                taggable
                                multiple
                                :state="errors.length > 0 ? false : null"
                                :create-option="tag => ({id:tag, name: tag })"
                                @input="limitSelected($event, 'glass_detail_id')"
                                :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                                :options="glassDetailsOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :reduce="option => option.id" label="name"
                                placeholder="Select Glass Details"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- additionalOptions -->
                <b-col v-if="withAdditionalOption(value.description_id)" :md="value.withGlassDetails ? '2': '4'">
                  <b-form-group :label="`Additional Options`" label-for="additional-options">
                    <validation-provider #default="{ errors }"
                                         :name="`additional options ${offer_elevation} item`">
                      <v-select id="additional-options" class="custom-select-style"
                                v-model="value.additional_option_ids" multiple
                                @input="calcAdditionalCost(value)"
                                :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                                :options="additionalOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :reduce="option => option.id" label="name"
                                placeholder="Select Additional Options"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <b-row>
                    <!-- Meter Price -->
                    <b-col md="4">
                      <b-form-group :label="`Meter Price`" :label-for="`Meter Price-${offer_elevation}-${refIndex}`">
                        <validation-provider #default="{ errors }"
                                             :name="`meter price ${offer_elevation} item`" rules="required">
                          <b-form-input
                              :id="`Meter Price-${offer_elevation}-${refIndex}`" type="number"
                              @keydown="onlyNumbers"
                              @wheel.prevent
                              @input="isValueInBetween($event, value.description_id)"
                              :debounce="1000"
                              @update="mapAutoSaveData($event,'meter_price')"
                              :state="errors.length > 0 || value.valueInBetween ? false : null"
                              placeholder="1"
                              v-model="value.meter_price"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small v-if="!errors[0]" class="text-danger">{{ value.valueInBetween }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Additions Cost -->
                    <b-col md="2">
                      <b-form-group class="calc-price-style" label="Additions Cost"
                                    label-for="Additions Cost">
                        {{
                          value.additional_options_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }} AED
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group :label="`SQM`" :label-for="`SQM-${offer_elevation}-${refIndex}`">
                        <template #label>
                          <span>SQM</span>
                          <feather-icon
                              v-b-tooltip.hover="'SQM is disabled while \'width\' or \'height\' input is filled '"
                              icon="InfoIcon"
                          />
                        </template>
                        <validation-provider #default="{ errors }"
                                             :name="`SQM ${offer_elevation} SQM`">
                          <b-form-input
                              :id="`SQM-${offer_elevation}-${refIndex}`" type="number"
                              @keydown="onlyNumbers"
                              @wheel.prevent
                              :debounce="1000"
                              :disabled="value.width>0 || value.height>0"
                              @update="mapAutoSaveData($event = $event === '' ? null :$event,'SQM')"
                              :state="errors.length > 0 ? false : null"
                              placeholder="1"
                              v-model="value.SQM"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Unit Price -->
                    <b-col md="3">
                      <b-form-group class="calc-price-style" label="Unit Price" label-for="Unit Price">
                        {{ calcTotal(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} AED
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="4">
                  <b-row>
                    <!-- Quantity -->
                    <b-col md="6">
                      <b-form-group :label="`Quantity`" :label-for="`Quantity-${offer_elevation}-${refIndex}`">
                        <validation-provider #default="{ errors }"
                                             :name="`quantity ${offer_elevation} item`" rules="required">
                          <b-form-input
                              :id="`Quantity-${offer_elevation}-${refIndex}`" type="number"
                              @keydown="onlyNumbers"
                              @wheel.prevent
                              :debounce="1000"
                              @update="mapAutoSaveData($event ,'quantity')"
                              @keypress="event => event.key === '.' ? event.preventDefault() : ''"
                              :state="errors.length > 0 ? false : null" placeholder="1"
                              v-model="value.quantity"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Total Price -->
                    <b-col md="6">
                      <b-form-group
                          class="font-weight-bolder calc-price-style" label="Total Price"
                          label-for="Total Price"
                      >
                      <span class="mt-1">
                        {{
                          (calcTotal(value) * value.quantity).toFixed(2)
                              .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }} AED
                      </span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

            <!-- Remove Button -->
            <b-col md="12" class="mb-50 d-flex">
              <b-button v-if="!isSubItem"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="mt-0 mt-md-2 mr-1"
                        :disabled="deleting || !value.category_id"
                        @click="repeatAgain(false)"
              >
                <span>
                  <feather-icon icon="PlusIcon" class="mr-25"/>
                <span>Add New Item</span>
                </span>
              </b-button>
              <b-button v-if="!isSubItem"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-secondary"
                        class="mt-0 mt-md-2 mr-1"
                        :disabled="deleting || !value.category_id"
                        @click="repeatAgain(true)"
              >
                <span>
                  <feather-icon icon="PlusIcon" class="mr-25"/>
                <span>Add New Sub-Item</span>
                </span>
              </b-button>
              <b-button v-if="isSubItem || canDeleteItem"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2 mr-1 ml-auto"
                        :disabled="deleting"
                        @click="removeItem()"
              >
                <span v-if="!deleting">
                  <feather-icon icon="XIcon" class="mr-25"/>
                <span>Delete</span>
                </span>
                <span v-else>
                  <span>Deleting...</span>
                  <b-spinner small variant="danger" class="ml-25"/>
                </span>
              </b-button>
              <!--              <b-dropdown-->
              <!--                  class="mt-0 mt-md-2"-->
              <!--                  v-if="!isSubItem"-->
              <!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
              <!--                  text="Add New"-->
              <!--                  variant="outline-primary"-->
              <!--              >-->
              <!--                <b-dropdown-item >Item</b-dropdown-item>-->
              <!--                <b-dropdown-item @click="repeatAgain(true)">Sub-Item</b-dropdown-item>-->
              <!--              </b-dropdown>-->
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {VBTooltip} from 'bootstrap-vue'
import {heightTransition} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import DescriptionService from "@/services/DescriptionService";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formatterMixin from '../formatter.js';
import Cleave from "vue-cleave-component";
import AutoSaveOfferService from "@/services/AutoSaveOfferService";

export default {
  components: {
    ValidationProvider, ValidationObserver,
    vSelect,
    Cleave
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [formatterMixin, heightTransition],
  props: [
    'value',
    'refIndex',
    'index',
    'offerId',
    'canDeleteItem',
    'isSubItem',
    'offer_elevation',
    'categoryLoader',
    'glassTypeLoader',
    'glassTypeOptions',
    'glassTypeOptionsWithDetailsOptions',
    'glassDetailsOptions',
    'additionalOptions',
    'categoryOptions',
  ],
  data() {
    return {
      DescriptionService: new DescriptionService(),
      autoSaveOfferService: new AutoSaveOfferService(),
      descriptionLoader: false,
      priceFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      creating: false,
      deleting: false
    }
  },
  methods: {
    repeatAgain(subItem = false) {
      if (subItem) {
        this.$emit('addSubItem')
      } else {
        this.$emit('addItem')
      }
    },
    removeItem() {
      if (this.isSubItem) {
        this.$emit('removeSubItem', this.value.id)
      } else {
        this.$emit('removeItem', this.value.id)
      }
      // this.value.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    selectProduct(value, clearInputs = false) {
      try {
        const selectedItem = this.categoryOptions.find(el => el.id == value);
        // console.log('selectProduct', item)
        this.value.formItemTouched = true
        this.value.withProductCategoryNote = selectedItem.with_note
        // this.value.itemOptions = undefined;
        // this.value.itemOptions = item.items;
        this.$set(this.value, 'itemOptions', selectedItem.items)
        if (clearInputs) {
          this.clearAfterSelectProduct()
          if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
            if (!this.value.id) {
              this.createItem({
                item: {
                  offer_elevation: this.offer_elevation,
                  product_category_id: this.value.category_id,
                  width: this.value.width,
                  height: this.value.height,
                  SQM: this.value.SQM,
                  parent_id: this.value.parent_id,
                  quantity: this.value.quantity,
                  order: this.index + 1
                }
              })
            } else {
              this.mapAutoSaveData(this.value.category_id, 'product_category_id')
            }
          }

        }
      } catch (err) {
        console.error('selectProduct', err)
      }
    },
    clearAfterSelectProduct() {
      this.value.item_id = undefined
      this.value.description_id = undefined
      this.value.meter_price = undefined
      this.value.additional_option_ids = []
      this.value.additional_options_cost = 0
      this.value.descriptionOptions = []
      this.value.withCustomGlassTypeSelect = 0
      this.value.glass_type_id = []
      this.value.glass_detail_id = []
      this.value.localGlassTypeOptions = this.glassTypeOptions
      if (this.value.withProductCategoryNote) {
        this.value.product_category_note = ''
      } else {
        this.value.product_category_note = undefined
      }
    },
    selectItem(itemId, clearInputs = false) {
      try {
        const item = this.value.itemOptions.find(el => el.id == itemId);
        this.value.formItemTouched = true
        this.value.reference_code_id = item.reference_code_id
        this.value.reference_code = item.reference_code
        this.value.withCustomGlassTypeSelect = item.with_custom_select
        this.value.withGlassDetails = item.with_glass_details
        let clearGlassTypeSelection = false
        if (item.with_custom_select) {
          clearGlassTypeSelection = this.value.localGlassTypeOptions !== this.glassTypeOptionsWithDetailsOptions;
          this.value.localGlassTypeOptions = this.glassTypeOptionsWithDetailsOptions
        } else {
          clearGlassTypeSelection = this.value.localGlassTypeOptions !== this.glassTypeOptions;
          this.value.localGlassTypeOptions = this.glassTypeOptions
        }
        this.value.descriptionOptions = []
        const descOptions = item.item_description.map(obj => {
          return {...obj, content: obj.description?.content}
        })
        this.$set(this.value, 'descriptionOptions', descOptions)
        if (clearInputs) {
          this.clearAfterSelectItem(clearGlassTypeSelection)
          this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
          this.mapAutoSaveData(this.value.item_id, 'item_id')
        }
      } catch (err) {
        console.error('selectItem', err)
      }
    },
    clearAfterSelectItem(clearGlassTypeSelection) {
      this.value.description_id = undefined
      this.value.meter_price = undefined
      this.value.additional_option_ids = []
      this.value.additional_options_cost = 0
      this.value.glass_detail_id = [];
      if (clearGlassTypeSelection) {
        this.value.glass_type_id = []
      }
    },
    selectDescription() {
      this.value.formItemTouched = true
      this.value.meter_price = undefined
      this.mapAutoSaveData(this.value.description_id, 'description_id')
    },
    calcTotal(item) {
      let width_x_height
      if (item.SQM > 0) {
        width_x_height = item.SQM
      } else {
        width_x_height = (item.width * item.height) / 1000000
      }
      if (width_x_height < 1) {
        width_x_height = 1
      }
      // if (item.descriptionOptions) {
      const checkWithAdditionalOption = item.descriptionOptions.find(option => option.description_id === item.description_id)
      if (checkWithAdditionalOption && checkWithAdditionalOption.additional_option === 'Yes') {
        item.unit_price = (((width_x_height * (item.meter_price ?? 0))) + +item.additional_options_cost).toFixed(2);
        return item.unit_price;
      } else {
        item.unit_price = item.meter_price ? ((width_x_height * (item.meter_price ?? 0))).toFixed(2) : 0
        return item.unit_price
      }
      // } else {
      //   return 0
      // }
    },
    initTrHeight() {
      this.trSetHeight(null)
    },
    withAdditionalOption(itemId) {
      // if (this.value.descriptionOptions) {
      const selectedDesc = this.value.descriptionOptions.find(el => el.description_id === itemId);
      return selectedDesc?.additional_option === 'Yes'
      // } else {
      //   return false
      // }
    },
    isValueInBetween(value, descriptionId) {
      this.value.valueInBetween = undefined
      this.value.formItemTouched = true
      const selectedDesc = this.value.descriptionOptions.find(option => option.description_id === descriptionId)
      if (selectedDesc) {
        const low = selectedDesc.low_price
        const high = selectedDesc.high_price
        if (low && high) {
          if (+value < low || +value > high) {
            this.value.valueInBetween = `Value should be between ${low} & ${high}`
          }
        } else {
          if (low && +value < low) {
            this.value.valueInBetween = `Value should be bigger than ${low}`
          }
          if (high && +value > high) {
            this.value.valueInBetween = `Value should be smaller than ${high}`
          }
        }
      }

    },
    minValue(val, errorKey) {
      this.value.formItemTouched = true
      this.value[errorKey] = undefined
      if (val < 0 && val !== '') {
        this.value[errorKey] = 'Min value is 0 mm'
      }
    },
    calcAdditionalCost(item) {
      this.value.formItemTouched = true
      const costArray = item.additional_option_ids
          .map(el => {
                return this.additionalOptions.find(option => option.id == el).cost
              }
          )
      if (costArray.length) {
        this.value.additional_options_cost = costArray.reduce((a, b) => {
          return (+a) + (+b)
        })
      } else {
        this.value.additional_options_cost = 0
      }
      this.mapAutoSaveData(this.value.additional_option_ids, 'additional_option_ids')
    },
    limitSelected(e, key = undefined) {
      this.value.formItemTouched = true
      if (e.length > 1) {
        e.shift()
      }
      if (key) {
        if (typeof this.value[key][0] === 'number') {
          this.mapAutoSaveData(this.value[key][0], key)
        } else {
          if (key === 'glass_type_id') {
            this.mapAutoSaveData(this.value[key][0], 'other_glass_type')
          }
          if (key === 'glass_detail_id') {
            this.mapAutoSaveData(this.value[key][0], 'other_glass_detail')
          }

        }
      }

    },
    fillData(test = null) {
      // console.log('this.value', this.value)
      // console.log('this.value.category_id', this.value.category_id)
      // console.warn('test', test)
      // console.log('fillData')
      if (this.value.category_id) {
        this.selectProduct(this.value.category_id)
        setTimeout(() => {
          if (this.value.item_id)
            this.selectItem(this.value.item_id)
        }, 1000)

      }
    },
    mapAutoSaveData(value, key) {
      if (this.value.id) {
        const data = {
          item: {
            offer_item_id: this.value.id,
            [key]: value
          }
        }
        this.autoSave(data)
      }
    },
    autoSave(data) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this.autoSaveOfferService.autoSave(this.offerId, data)
            .then(res => {
              console.log(res)
            })
      }
    },
    createItem(data) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this.creating = true
        this.autoSaveOfferService.createItem(this.offerId, data)
            .then(res => {
              console.log(res)
              this.value.id = res.id
            })
            .finally(() => {
              this.creating = false
            })
      }
    }
  },
  mounted() {
    this.fillData()
    this.initTrHeight()

  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables/variables";

.repeater-form {
  //overflow: hidden;
  transition: .35s height;
}

.calc-price-style {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

::v-deep .vs__selected-options {
  overflow: hidden;
}

#glass-type ::v-deep .vs__selected-options, #additional-options ::v-deep .vs__selected-options {
  flex-wrap: nowrap;
}

#glass-type ::v-deep .vs__selected, #additional-options ::v-deep .vs__selected {
  z-index: 10;
  padding-right: 20px;
  position: relative;
}

#glass-type ::v-deep .vs__selected button, #additional-options ::v-deep .vs__selected button {
  position: absolute;
  right: 0;
  background-color: $primary;
  width: 16px;
  height: 100%;
  place-content: center;

}
</style>
