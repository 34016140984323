<template>
  <div class="">
    <b-row>
      <b-col md="4">
        <b-form-group label="Total Items Cost" label-for="subtotal">
          <b-input-group
              append="AED"
              class="input-group-merge">
            <cleave
                readonly
                id="subtotal"
                :value="subtotal_price"
                class="form-control"
                :raw="false"
                :options="priceFormat"
                placeholder="10,000"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Discount value" label-for="discount">
          <validation-provider #default="{ errors }" name="discount" rules="required">
            <b-input-group
                :class="errors[0] || paymentTermsErrors.discountError ? 'border-danger-for-merge-input' : ''">
              <cleave
                  class="form-control"
                  :raw="true"
                  :options="priceFormat"
                  id="discount"
                  ref="localDiscount"
                  @blur="checkMaxNumber"
                  :state="errors.length > 0 ? false : paymentTermsErrors.discountError ? false : null"
                  placeholder="Discount"
                  v-model="localDiscount"
              />
              <b-input-group-append is-text class="cursor-pointer custom-append" @click="toggleInput()">{{
                  discountType
                }}
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="paymentTermsErrors.discountError && !errors[0]"
                   class="text-danger">{{ paymentTermsErrors.discountError }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!--      <b-col md="3">-->
      <!--        <b-form-group label="Discount" label-for="discount">-->
      <!--          <b-input-group>-->
      <!--            <b-form-input id="discount" v-model="localDiscount"-->
      <!--                          type="number"-->
      <!--                          :formatter="formatter"-->
      <!--                          @input="test"-->
      <!--                          @keydown="onlyNumbers"-->
      <!--      @wheel.prevent-->
      <!--                          placeholder="Discount"/>-->
      <!--            <b-input-group-append is-text>%</b-input-group-append>-->
      <!--          </b-input-group>-->
      <!--        </b-form-group>-->
      <!--      </b-col>-->
      <b-col md="4">
        <b-form-group label="Discount amount" label-for="total">
          <b-input-group
              append="AED"
              class="input-group-merge">
            <cleave
                readonly
                id="discountAmount"
                v-model="discountAmount"
                class="form-control"

                :raw="false"
                :options="priceFormat"
                placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Total Cost" label-for="total">
          <b-input-group
              append="AED"
              class="input-group-merge">
            <cleave
                readonly
                id="total"
                v-model="Math.floor(totalPrice)"
                class="form-control"

                :raw="false"
                :options="priceFormat"
                placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Project Duration" label-for="estimated_duration">
          <template #label>
            <span>Project Duration</span>
            <feather-icon
                v-b-tooltip.hover="`The default project duration is ${projectDuration} days`"
                icon="InfoIcon"
            />
          </template>
          <validation-provider #default="{ errors }" name="Project Duration" rules="required">
            <b-input-group
                :class="errors[0] ? 'border-danger-for-merge-input' : ''"
                append="Working days"
                class="input-group-merge">
              <b-form-input id="estimated_duration" v-model="localEstimatedDuration"
                            type="number"
                            @update="updateEstimatedDuration()"
                            :debounce="500"
                            :state="errors.length > 0 ? false : null"
                            @keydown="onlyNumbers"
                            @wheel.prevent
                            :placeholder="projectDuration"/>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Number of Frames" label-for="NoF">
          <b-form-input
              readonly
              :value="NoF"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <hr>
      </b-col>
      <b-col md="12">
        <h3 class="mb-2">Payment Terms</h3>
      </b-col>
        <b-col md="6">
          <b-form-group label="Advance Payment" label-for="advancePayment">
            <template #label>
              <LabelInput
                  :value="localLabels[0].name"
                  @update="labelChanged($event, 0)"
              />
            </template>
            <validation-provider #default="{ errors }" name="field">
              <b-input-group
                  :class="errors[0] || paymentTermsErrors.advancePaymentError || paymentTermsErrors.sumPaymentTermsError ? 'border-danger-for-merge-input' : ''">
                <b-form-input id="advancePayment" v-model="value[0].amount"
                              type="number"
                              @update="checkMaxNumberForPaymentTerms($event,'advancePaymentError', 0)"
                              :debounce="500"
                              :state="errors.length > 0 || paymentTermsErrors.advancePaymentError ? false : null"
                              @keydown="onlyNumbers"
                              @wheel.prevent
                              placeholder=""/>
                <b-input-group-append is-text>%</b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              <small v-if="paymentTermsErrors.advancePaymentError && !errors[0]"
                     class="text-danger">{{ paymentTermsErrors.advancePaymentError }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-overlay
              variant="white"
              :show="loadingSplitPayment_1"
              spinner-variant="primary"
              blur="1"
              opacity=".75"
              rounded="sm"
          >
            <div :class="value[1].split && value[1].split.length ? 'card card-body':''">
              <b-form-group label="Paid After Delivering the Alum. Frames of All Project, Cash or Current Cheque"
                            label-for="paidAfterAlum">
                <template #label>
                  <LabelInput
                      :value="localLabels[1].name"
                      @update="labelChanged($event, 1)"
                  />
                </template>
                <validation-provider #default="{ errors }" name="field">
                  <div class="d-flex">
                    <b-input-group class="mr-1"
                                   :class="errors[0] || paymentTermsErrors.paidAfterAlumError || paymentTermsErrors.sumPaymentTermsError? 'border-danger-for-merge-input' : ''">
                      <b-form-input id="paidAfterAlum" v-model="value[1].amount"
                                    type="number"
                                    @update="checkMaxNumberForPaymentTerms($event,'paidAfterAlumError', 1)"
                                    :debounce="500"
                                    :state="errors.length > 0 || paymentTermsErrors.paidAfterAlumError ? false : null"
                                    @keydown="onlyNumbers"
                                    @wheel.prevent
                                    placeholder=""/>
                      <b-input-group-append is-text>%</b-input-group-append>
                    </b-input-group>
                    <b-button v-if="!value[1].split || !value[1].split.length"
                              @click="splitAmount(1)"
                              :disabled="value[1].amount<1"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="btn-icon"
                    >
                      <feather-icon icon="PlusIcon" size="20"/>
                    </b-button>
                    <b-button v-if="value[1].split && value[1].split.length" @click="removeSplitAmount(1)"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="btn-icon"
                    >
                      <feather-icon icon="MinusIcon" size="20"/>
                    </b-button>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="paymentTermsErrors.paidAfterAlumError && !errors[0]"
                         class="text-danger">{{ paymentTermsErrors.paidAfterAlumError }}</small>
                </validation-provider>
              </b-form-group>
              <b-row v-if="value[1].split && value[1].split.length">
                <b-col md="6">
                  <b-form-group label="Paid After delivering the Alum. Frames of All Project, Cash or Current Cheque"
                                label-for="splitPaidAfterAlumFirst">
                    <template #label>
                      <LabelInput
                          :value="localLabels[1].split[0].name"
                          @update="labelChanged($event, 1, 0)"
                      />
                    </template>
                    <validation-provider #default="{ errors }" name="field">
                      <b-input-group
                          :class="errors[0] || paymentTermsErrors.split_1_Error || paymentTermsErrors.sumPaymentTermsError? 'border-danger-for-merge-input' : ''">
                        <b-form-input id="splitPaidAfterAlumFirst" v-model="value[1].split[0].amount"
                                      type="number"
                                      :state="errors.length > 0 || paymentTermsErrors.split_1_Error ? false : null"
                                      @keydown="onlyNumbers"
                                      @update="autoSavePaymentAmount(value[1].split[0].id,value[1].split[0].amount)"
                                      :debounce="500"
                                      @wheel.prevent
                                      placeholder=""/>
                        <b-input-group-append is-text>%</b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="paymentTermsErrors.split_1_Error && !errors[0]"
                             class="text-danger">{{ paymentTermsErrors.split_1_Error }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Paid After Installing the Alum. Frames of All Project, Cash or Current Cheque"
                                label-for="splitPaidAfterAlumSecond">
                    <template #label>
                      <LabelInput
                          :value="localLabels[1].split[1].name"
                          @update="labelChanged($event, 1, 1)"
                      />
                    </template>
                    <validation-provider #default="{ errors }" name="field">
                      <b-input-group
                          :class="errors[0] || paymentTermsErrors.split_1_Error || paymentTermsErrors.sumPaymentTermsError? 'border-danger-for-merge-input' : ''">
                        <b-form-input id="splitPaidAfterAlumSecond" v-model="value[1].split[1].amount"
                                      type="number"
                                      :state="errors.length > 0 || paymentTermsErrors.split_1_Error ? false : null"
                                      @keydown="onlyNumbers"
                                      @update="autoSavePaymentAmount(value[1].split[1].id,value[1].split[1].amount)"
                                      :debounce="500"
                                      @wheel.prevent
                                      placeholder=""/>
                        <b-input-group-append is-text>%</b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="paymentTermsErrors.split_1_Error && !errors[0]"
                             class="text-danger">{{ paymentTermsErrors.split_1_Error }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

          </b-overlay>
        </b-col>
        <b-col md="6">
          <b-overlay
              variant="white"
              :show="loadingSplitPayment_2"
              spinner-variant="primary"
              blur="1"
              opacity=".75"
              rounded="sm"
          >
            <div :class="value[2].split && value[2].split.length ? 'card card-body':''">
              <b-form-group label="Paid After Delivering Glass. Of All Project, Cash or Current Cheque"
                            label-for="paidAfterGlass">
                <template #label>
                  <LabelInput
                      :value="localLabels[2].name"
                      @update="labelChanged($event, 2)"
                  />
                </template>
                <validation-provider #default="{ errors }" name="field">
                  <div class="d-flex">
                    <b-input-group class="mr-1"
                                   :class="errors[0] || paymentTermsErrors.paidAfterGlassError || paymentTermsErrors.sumPaymentTermsError ? 'border-danger-for-merge-input' : ''">
                      <b-form-input id="paidAfterGlass" v-model="value[2].amount"
                                    type="number"
                                    @update="checkMaxNumberForPaymentTerms($event,'paidAfterGlassError', 2)"
                                    :debounce="500"
                                    :state="errors.length > 0 || paymentTermsErrors.paidAfterGlassError ? false : null"
                                    @keydown="onlyNumbers"
                                    @wheel.prevent
                                    placeholder=""/>
                      <b-input-group-append is-text>%</b-input-group-append>
                    </b-input-group>
                    <b-button v-if="!value[2].split || !value[2].split.length"
                              @click="splitAmount(2)"
                              :disabled="value[2].amount<1"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="btn-icon"
                    >
                      <feather-icon icon="PlusIcon" size="20"/>
                    </b-button>
                    <b-button v-if="value[2].split && value[2].split.length" @click="removeSplitAmount(2)"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="btn-icon"
                    >
                      <feather-icon icon="MinusIcon" size="20"/>
                    </b-button>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="paymentTermsErrors.paidAfterGlassError && !errors[0]"
                         class="text-danger">{{ paymentTermsErrors.paidAfterGlassError }}</small>
                </validation-provider>
              </b-form-group>

              <b-row v-if="value[2].split && value[2].split.length">
                <b-col md="6">
                  <b-form-group label="Paid After Delivering Glass. Of All Project, Cash or Current Cheque"
                                label-for="splitPaidAfterGlassFirst">
                    <template #label>
                      <LabelInput
                          :value="localLabels[2].split[0].name"
                          @update="labelChanged($event, 2, 0)"
                      />
                    </template>
                    <validation-provider #default="{ errors }" name="field">
                      <b-input-group
                          :class="errors[0] || paymentTermsErrors.split_2_Error || paymentTermsErrors.sumPaymentTermsError? 'border-danger-for-merge-input' : ''">
                        <b-form-input id="splitPaidAfterGlassFirst" v-model="value[2].split[0].amount"
                                      type="number"
                                      :state="errors.length > 0 || paymentTermsErrors.split_2_Error ? false : null"
                                      @keydown="onlyNumbers"
                                      @update="autoSavePaymentAmount(value[2].split[0].id,value[2].split[0].amount)"
                                      :debounce="500"
                                      placeholder=""/>
                        <b-input-group-append is-text>%</b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="paymentTermsErrors.split_2_Error && !errors[0]"
                             class="text-danger">{{ paymentTermsErrors.split_2_Error }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Paid After Installation Glass. Of All Project, Cash or Current Cheque"
                                label-for="splitPaidAfterGlassSecond">
                    <template #label>
                      <LabelInput
                          :value="localLabels[2].split[1].name"
                          @update="labelChanged($event, 2, 1)"
                      />
                    </template>
                    <validation-provider #default="{ errors }" name="field">
                      <b-input-group
                          :class="errors[0] || paymentTermsErrors.split_2_Error || paymentTermsErrors.sumPaymentTermsError? 'border-danger-for-merge-input' : ''">
                        <b-form-input id="splitPaidAfterGlassSecond" v-model="value[2].split[1].amount"
                                      type="number"
                                      :state="errors.length > 0 || paymentTermsErrors.split_2_Error ? false : null"
                                      @keydown="onlyNumbers"
                                      @update="autoSavePaymentAmount(value[2].split[1].id,value[2].split[1].amount)"
                                      :debounce="500"
                                      @wheel.prevent
                                      placeholder=""/>
                        <b-input-group-append is-text>%</b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="paymentTermsErrors.split_2_Error && !errors[0]"
                             class="text-danger">{{ paymentTermsErrors.split_2_Error }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-col>
        <b-col md="6">
          <b-form-group label="Paid Before Handing Over. Of All Project, Cash or Current Cheque"
                        label-for="paidAfterHanding">
            <template #label>
              <LabelInput
                  :value="localLabels[3].name"
                  @update="labelChanged($event, 3)"
              />
            </template>
            <validation-provider #default="{ errors }" name="field">
              <b-input-group
                  :class="errors[0] || paymentTermsErrors.paidAfterHandingError || paymentTermsErrors.sumPaymentTermsError ? 'border-danger-for-merge-input' : ''">
                <b-form-input id="paidAfterHanding" v-model="value[3].amount"
                              type="number"
                              @update="checkMaxNumberForPaymentTerms($event,'paidAfterHandingError', 3)"
                              :debounce="500"
                              :state="errors.length > 0 || paymentTermsErrors.paidAfterHandingError ? false : null"
                              @keydown="onlyNumbers"
                              @wheel.prevent
                              placeholder=""/>
                <b-input-group-append is-text>%</b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              <small v-if="paymentTermsErrors.paidAfterHandingError && !errors[0]"
                     class="text-danger">{{ paymentTermsErrors.paidAfterHandingError }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      <b-col v-if="paymentTermsErrors.sumPaymentTermsError" md="12">
        <h6 class="text-danger">
          {{ paymentTermsErrors.sumPaymentTermsError }}
        </h6>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BRow
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import formatterMixin from '../formatter.js';
import LabelInput from "@/views/components/seller/OfferForm/LabelInput";
import AutoSaveOfferService from "@/services/AutoSaveOfferService";

export default {
  name: "Four",
  components: {
    LabelInput,
    ValidationProvider,
    ValidationObserver,
    BRow, BButton,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    Cleave,
    BInputGroupAppend
  },
  directives: {
    Ripple,
  },
  mixins: [formatterMixin],
  props: ['value', 'offerId', 'subtotal_price', 'discount', 'discount_percentage', 'estimated_duration', 'NoF'],
  data() {
    return {
      autoSaveOfferService: new AutoSaveOfferService(),
      localDiscount: 0,
      localEstimatedDuration: null,
      localLabels: [
        {name: '', editMode: false, split: undefined},
        {name: '', editMode: false, split: [{name: '', editMode: false,}, {name: '', editMode: false,}]},
        {name: '', editMode: false, split: [{name: '', editMode: false,}, {name: '', editMode: false,}]},
        {name: '', editMode: false, split: undefined},
      ],
      show: true,
      priceFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      paymentTermsErrors: {
        discountError: undefined,
        advancePaymentError: undefined,
        paidAfterAlumError: undefined,
        split_1_Error: undefined,
        paidAfterGlassError: undefined,
        split_2_Error: undefined,
        paidAfterHandingError: undefined,
        sumPaymentTermsError: undefined

      },
      discountType: undefined,
      loadingSplitPayment_1: false,
      loadingSplitPayment_2: false
    }
  },
  computed: {
    totalPrice: {
      get() {
        if (this.discountType === 'AED' && +this.localDiscount <= this.subtotal_price)
          return this.subtotal_price - this.localDiscount
        const subtotal = '' + this.subtotal_price
        if (+this.localDiscount <= 100)
          return this.subtotal_price - (+subtotal * +this.localDiscount) / 100
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
      }
    },
    discountAmount: {
      get() {
        if (this.discountType === 'AED') return this.localDiscount
        const subtotal = '' + this.subtotal_price
        if (+this.localDiscount <= 100)
          return (+subtotal * +this.localDiscount) / 100
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
      }
    },
    projectDuration: {
      get() {
        const subtotal = '' + this.subtotal_price
        if (+subtotal <= 120000) {
          return '45'
        } else {
          return '60'
        }
      },
      set(newVal) {
        console.log(newVal)
        this.$emit('update:estimated_duration', newVal)
      }
    }
  },
  watch: {
    value: {
      handler: function (newValue) {
        this.paymentTermsErrors.sumPaymentTermsError = undefined
        this.paymentTermsErrors.split_1_Error = undefined
        this.paymentTermsErrors.split_2_Error = undefined
        let sumAmount = 0
        let sumSplit_1_Amount = undefined
        let sumSplit_2_Amount = undefined
        newValue.forEach((input, i) => {
          sumAmount += +input.amount
          if (i === 1 && input.split && input.split.length) {
            sumSplit_1_Amount = 0
            sumSplit_1_Amount += +input.split[0].amount
            sumSplit_1_Amount += +input.split[1].amount
          }
          if (i === 2 && input.split && input.split.length) {
            sumSplit_2_Amount = 0
            sumSplit_2_Amount += +input.split[0].amount
            sumSplit_2_Amount += +input.split[1].amount
          }
        })
        if (+sumAmount > 100) {
          this.paymentTermsErrors.sumPaymentTermsError = 'Sum of all payment terms inputs must be 100%'
        }
        if (sumSplit_1_Amount && +this.value[1].amount !== sumSplit_1_Amount) {
          this.paymentTermsErrors.split_1_Error = 'The sum of this field must equal ' + this.value[1].amount
        }
        if (sumSplit_2_Amount && +this.value[2].amount !== sumSplit_2_Amount) {
          this.paymentTermsErrors.split_2_Error = 'The sum of this field must equal ' + this.value[2].amount
        }
        this.value.forEach((val, index) => {
          this.localLabels[index].name = val.name
          if (val.split && val.split.length) {
            this.localLabels[index].split[0].name = val.split[0].name
            this.localLabels[index].split[1].name = val.split[1].name
          }
        })
      }
      ,
      deep: true
    },
    discount(newVal) {
      this.localDiscount = newVal
    },
    estimated_duration(newVal) {
      this.localEstimatedDuration = newVal
    },
    discount_percentage(newVal) {
      console.log("discount percentage  " + newVal)
      if (newVal == 0)
        this.discountType = 'AED'
      else
        this.discountType = '%'
    },
  },
  methods: {
    labelChanged(value, index, splitIndex = undefined) {
      console.log(this.value)
      if (splitIndex !== undefined) {
        this.value[index].split[splitIndex].name = value

        this.localLabels[index].split[splitIndex].name = value
        this.localLabels[index].split[splitIndex].editMode = false
      } else {
        this.value[index].name = value
        this.localLabels[index].name = value
        this.localLabels[index].editMode = false
      }
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        if (splitIndex !== undefined) {
          this.autoSave({
            payment: {
              offer_payment_id: this.value[index].split[splitIndex].id,
              name: this.value[index].split[splitIndex].name,
              amount: this.value[index].split[splitIndex].amount,
              parent_id: this.value[index].split[splitIndex].parent_id
            }
          })
        } else {
          this.autoSave({
            payment: {
              offer_payment_id: this.value[index].id,
              name: this.value[index].name,
              amount: this.value[index].amount
            }
          })
        }
      }
    },
    checkMaxNumber(e) {
      this.paymentTermsErrors.discountError = undefined
      if (+e > 100 && this.discountType === '%') {
        this.paymentTermsErrors.discountError = 'Max value is 100%'
      } else if (+e > this.subtotal_price && this.discountType === 'AED') {
        this.paymentTermsErrors.discountError = `Max value is ${this.subtotal_price}`
      }
      if (!this.paymentTermsErrors.discountError) {
        this.autoSave({
          discount: +e,
          discount_percentage: this.discount_percentage
        })
        this.$emit('update:discount', +this.localDiscount)
      }
    },
    checkMaxNumberForPaymentTerms(e, paymentInput, index) {
      this.paymentTermsErrors[paymentInput] = undefined
      if (e > 100) {
        this.paymentTermsErrors[paymentInput] = 'Max value is 100%'
      } else if (e === 0 || e === '') {
        if (this.value[index].split && this.value[index].split.length) {
          this.removeSplitAmount(index)
        }
      }
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this.autoSave({
          payment: {
            offer_payment_id: this.value[index].id,
            name: this.value[index].name,
            amount: this.value[index].amount === '' ? 0 : this.value[index].amount
          }
        })
      }
    },
    async splitAmount(index) {
      console.log('this.localLabels[index]', this.localLabels[index])
      if (this.value[index].amount === '' || this.value[index].amount === 0) return
      if (this.localLabels[index].split[0].name === '') {
        this.value[index].split = []
        this.value[index].split = [
          {
            name: index === 1 ?
                "Paid After delivering the Alum. Frames of All Project, Cash or Current Cheque" :
                "Paid After Delivering Glass. Of All Project, Cash or Current Cheque",
            amount: this.value[index].amount / 2
          },
          {
            name: index === 1 ?
                "Paid After Installing the Alum. Frames of All Project, Cash or Current Cheque" :
                "Paid After Installation Glass. Of All Project, Cash or Current Cheque",
            amount: this.value[index].amount / 2
          },
        ]
        this.localLabels[index].split[0].name = this.value[index].split[0].name
        this.localLabels[index].split[1].name = this.value[index].split[1].name
      } else {
        this.value[index].split = []
        this.value[index].split[0] = {}
        this.value[index].split[0].name = this.localLabels[index].split[0].name
        this.value[index].split[0].amount = this.value[index].amount / 2
        this.value[index].split[1] = {}
        this.value[index].split[1].name = this.localLabels[index].split[1].name
        this.value[index].split[1].amount = this.value[index].amount / 2
      }
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this['loadingSplitPayment_' + index] = true
        let [split1, split2] = await Promise.all([this.createSplitPayment({
          payment: {
            name: this.value[index].split[0].name,
            amount: this.value[index].amount / 2,
            parent_id: this.value[index].id
          }
        }), this.createSplitPayment({
          payment: {
            name: this.value[index].split[1].name,
            amount: this.value[index].amount / 2,
            parent_id: this.value[index].id
          }
        })]);
        console.log('hasan')
        this.value[index].split[0].id = split1.id
        this.value[index].split[1].id = split2.id
        console.log(split1, split2, 'hasssaan')
        this['loadingSplitPayment_' + index] = false
      }
    },
    async removeSplitAmount(index) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this['loadingSplitPayment_' + index] = true
        let [split1, split2] = await Promise.all(
            [
              this.autoSaveOfferService.deleteSplitPayment(this.value[index].split[0].id),
              this.autoSaveOfferService.deleteSplitPayment(this.value[index].split[1].id)
            ]);
        console.log(split1, split2, 'hasssaan')
        this['loadingSplitPayment_' + index] = false
        this.value[index].split = undefined
      } else {
        this.value[index].split = undefined
      }
    },
    test(e) {
      console.log(e)
    },
    toggleInput() {
      this.discountType = this.discountType === '%' ? 'AED' : '%'
      const localDiscountPercentage = this.discountType === '%' ? 1 : 0
      console.log("local  " + localDiscountPercentage)
      this.$emit('update:discount_percentage', localDiscountPercentage)

      this.$nextTick(() => {
        this.checkMaxNumber(this.localDiscount)
      })
    },
    updateEstimatedDuration() {
      this.$emit('update:estimated_duration', this.localEstimatedDuration)
      this.autoSave({estimated_duration: this.localEstimatedDuration})
    },
    createSplitPayment(data) {
      return this.autoSaveOfferService.createSplitPayment(this.offerId, data)
    },
    autoSavePaymentAmount(offer_payment_id, amount) {
      this.autoSave({
        payment: {
          offer_payment_id: offer_payment_id,
          amount: amount === '' ? 0 : amount
        }
      })
    },
    autoSave(data) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this.autoSaveOfferService.autoSave(this.offerId, data)
            .then(res => {
              console.log(res)
            })
      }
    },
  },
  mounted() {
    this.localDiscount = this.discount
    console.log("percentage" + this.discount_percentage)
    if (this.discount_percentage == 0)
      this.discountType = 'AED'
    else
      this.discountType = '%'
    this.value.forEach((val, index) => {
      this.localLabels[index].name = val.name
      if (val.split && val.split.length) {
        this.localLabels[index].split[0].name = val.split[0].name
        this.localLabels[index].split[1].name = val.split[1].name
      }
    })
    console.log(this.localLabels)
  }
}
</script>

<style scoped>
.btn.btn-icon {
  padding: .5rem .6rem;
}

.custom-append .input-group-text {
  background-color: #F4C522;
  color: #ffffff;
}
</style>
