<template>
  <div class="position-relative pb-1">
    <b-overlay
        variant="white"
        :show="submitting"
        spinner-variant="primary"
        blur="1"
        opacity=".75"
        rounded="sm"
    >
      <form-wizard ref="offerForm" color="#7367F0" :title="null" :subtitle="null" shape="square"
                   finish-button-text="Submit" back-button-text="Previous" class="mb-3" @on-complete="submit">

        <!--             personal details tab-->
        <tab-content title="Client contact information" icon="feather icon-user" :before-change="validationFormInfo">
          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <b-row>
                  <b-col cols="6">
                    <h5 class="mb-0">Client contact information</h5>
                    <small class="text-muted">Enter Your Client Info.</small>
                  </b-col>
                  <b-col cols="6">
                    <b-button id="show-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="float-right"
                              variant="primary" @click="$refs.customerModel.open()">Add Customer
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="mobile number" rules="required">
                  <b-form-group label="Mobile number" label-for="mobile-number"
                                :state="(errors.length > 0 || isValidNumber === false) ? false : null">
                    <div class="position-relative">
                      <VuePhoneNumberInput v-model="value.customer.phone_number" color="#F4C522" error-color="#EA5455"
                                           :required="true"
                                           :error="isValidNumber === false" default-country-code="AE"
                                           :loader="customerLoader"
                                           :debounce="1000"
                                           :no-example="noExample"
                                           @update="saveFormattedNumber"/>
                      <ul class="number shadow-lg" v-show="customers.length > 0">
                        <li v-for="customer in customers" @click="setCustomer(customer)" :key="customer.id"
                            class="mb-2 border-bottom number">{{ customer.phone_number }}
                        </li>
                      </ul>
                    </div>
                    <b-form-invalid-feedback class="d-flex flex-column"
                                             :state="errors.length > 0 || isValidNumber === false ? false : null">
                      <span>{{ errors[0] }}</span>
                      <span v-if="isValidNumber === false">Please enter a valid number</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group label="WhatsApp number is the Same as Mobile Number" label-for="">
                  <b-form-checkbox v-model="isSameNumber" @change="changeCheck" name="check-button" class="z-index-0"
                                   switch inline>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col v-if="!isSameNumber" md="6">
                <validation-provider #default="{ errors }" name="WhatsApp number" rules="required">
                  <b-form-group label="WhatsApp Number" label-for="whatsapp-number"
                                :state="errors.length > 0 || isValidWhatsappNumber === false ? false : null">
                    <template #label>
                      <span>WhatsApp Number</span>
                      <img style="width: 16px;margin-left: 1rem;"
                           :src="require('@/assets/images/icons/icon-whatsapp.svg')" alt="">
                    </template>
                    <VuePhoneNumberInput v-model="value.customer.whatsapp_number" color="#F4C522" error-color="#EA5455"
                                         :required="true"
                                         :error="isValidWhatsappNumber === false"
                                         :default-country-code="whatsappPhoneCountryCode"
                                         :no-example="noExample"
                                         @update="saveFormattedWhatsAppNumber"/>
                    <b-form-invalid-feedback class="d-flex flex-column"
                                             :state="errors.length > 0 || isValidWhatsappNumber === false ? false : null">
                      <span>{{ errors[0] }}</span>
                      <span v-if="isValidWhatsappNumber === false">Please enter a valid number</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group label="First Name" label-for="first-name">
                  <validation-provider #default="{ errors }" name="first name" rules="required">
                    <b-form-input disabled id="first-name" v-model="value.customer.first_name"
                                  placeholder="First Name"
                                  :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Last Name" label-for="last-name">
                  <validation-provider #default="{ errors }" name="last name" rules="required">
                    <b-form-input disabled id="last-name" v-model="value.customer.last_name"
                                  :state="errors.length > 0 ? false : null" placeholder="Last Name"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!--      Project Details-->

        <tab-content title="Project Details" icon="feather icon-file-text" :before-change="validationForm">
          <validation-observer ref="accountRules" tag="form">
            <step-two two
                      ref="stepTwo"
                      v-model="value"
            />
          </validation-observer>
        </tab-content>

        <!--      Item Details-->

        <tab-content title="Item Details" icon="feather icon-file-text" :before-change="validationItemForm">
          <validation-observer ref="itemRules" tag="form">
            <Step-three
                ref="stepThree"
                :offerId="value.id"
                v-model="value.itemDetails"
            />
          </validation-observer>
        </tab-content>

        <!--      Payment Terms-->

        <tab-content title="Payment Terms" icon="feather icon-dollar-sign"
                     :before-change="validationPaymentForm">
          <validation-observer ref="paymentRules" tag="form">
            <StepFour v-model="value.payments"
                      :offerId="value.id"
                      :discount.sync="value.discount"
                      :estimated_duration.sync="value.estimated_duration"
                      :discount_percentage.sync="value.discount_percentage"
                      :subtotal_price="value.subtotal_price"
                      :NoF="NoF"
                      ref="stepFour"
            />
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <customer-model ref="customerModel" @result="setCustomer"/>
    <b-modal
        v-model="showInvalidItemsListModal"
        centered
        title="List items with missing data"
        ok-only
        ok-title="Ok"
    >
      <b-table
          :striped="true"
          :outlined="true"
          :items="itemsNotValid"

          :fields="fields"
      />
    </b-modal>
  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import DatePicker from 'vue2-datepicker'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import CustomerModel from './CustomerModel.vue'
import StepTwo from './steps/two.vue'
import StepThree from './steps/three.vue'
import StepFour from './steps/Four'
import {required} from '@validations'
import ApiService from "@/services/shared/http/ApiService";
import CustomerService from "@/services/CustomerService";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import offerStoreModule from "@/views/components/seller/OfferForm/store/offerStore";
import {debounceFn} from '@/libs/debounceFn'
import OfferService from "@/services/OfferService";
import AutoSaveOfferService from "@/services/AutoSaveOfferService";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard, StepTwo,
    TabContent, StepThree,
    StepFour,
    CustomerModel,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    DatePicker,
    Cleave,
    VuePhoneNumberInput,
  },
  directives: {
    Ripple,
  },
  props: ['value'],
  data() {
    return {
      apiService: new ApiService(),
      offerService: new OfferService(),
      autoSaveOfferService: new AutoSaveOfferService(),
      CustomerService: new CustomerService(),
      customers: [],
      customerLoader: false,
      // phoneNumber: '',
      // whatsappNumber: '',
      initItemObj: {...this.$store.state.app.initItemObj},
      isValidNumber: undefined,
      isValidWhatsappNumber: true,
      submitting: false,
      phoneCountryCode: 'AE',
      whatsappPhoneCountryCode: 'AE',
      isSameNumber: true,
      priceFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      required,
      noExample: true,
      customerListDebounce: null,
      selectFromCustomersList: false,
      NoF: 0,
      itemsNotValid: [],
      showInvalidItemsListModal: false,
      fields: [
        {
          key: 'elevation_name',
          label: 'Elevation Name',
        },
        {
          key: 'item_reference_code',
          label: 'Ref Code',
        },
      ],
    }
  },
  mounted() {
    this.$refs.offerForm.activateAll()
    this.$store.dispatch("appOfferForm/offerStatus", this.value.publish_status)
    this.customerListDebounce = debounceFn((newVal) => {
      // console.log('debounceFn((newVal)', newVal)
      this.customerLoader = true;
      this.customers = []
      this.CustomerService.index({phoneNumber: newVal.replaceAll(' ', '')}).then(res => {
        this.customers = res.data;
      }).catch(() => {
      }).finally(() => {
        this.customerLoader = false;
      })
    }, 1000)
  },
  watch: {
    'value.customer.phone_number'(value, old) {
      console.log('value.customer.phone_number', value, 'old', old)
      if (!value || old?.includes('+') || value?.includes('+')) return;
      // this.debounce(value)
      console.log('this.selectFromCustomersList', this.selectFromCustomersList)
      if (!this.selectFromCustomersList) {
        this.customerLoader = true
        this.customerListDebounce(value)
      }

    },
    '$store.state.appOfferForm.itemSelected'(newVal) {
      // console.log('$store.state.appOfferForm.itemSelected(newVal)', newVal)
      if (newVal) {
        this.$refs.stepThree.setRefCode()
        this.$store.state.appOfferForm.itemSelected = false
      }
    },
    'value.publish_status'(newValue) {
      console.log('newValue value.publish_status', newValue)
      this.$store.dispatch("appOfferForm/offerStatus", newValue)
    }
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    isValid(elevationItem) {
      try {
        return !(
            elevationItem.category_id == undefined ||
            elevationItem.item_id == undefined ||
            elevationItem.description_id == undefined ||
            elevationItem.meter_price == undefined ||
            elevationItem.meter_price == '' ||
            elevationItem.quantity == undefined ||
            elevationItem.quantity == '' ||
            elevationItem.valueInBetween ||
            elevationItem.widthError ||
            elevationItem.heightError ||
            ((elevationItem.height == '' || elevationItem.width == '') && elevationItem.SQM == '') ||
            (elevationItem.glass_type_id.length == 0 && elevationItem.product_category_note == undefined) ||
            (elevationItem.withGlassDetails && elevationItem.glass_detail_id.length == 0)
        );
      } catch (e) {
        console.error('isValid function ====>', e)
      }
    },

    validationItemForm() {
      return new Promise((resolve, reject) => {
        this.$refs.itemRules.validate().then(success => {
          if (success) {
            this.value.subtotal_price = 0
            this.NoF = 0
            if (this.value.itemDetails.offer_tab === 'Elevation') {
              this.value.itemDetails.elevations.forEach(elevation => {
                elevation.items.forEach(item => {
                  this.value.subtotal_price += (+item.unit_price * +item.quantity)
                  if (this.isValid(item)) {
                    this.NoF += +item.quantity
                  }
                  if (item.sub_items) {
                    item.sub_items.forEach(subItem => {
                      this.value.subtotal_price += (+subItem.unit_price * +subItem.quantity)
                    })
                  }
                })
              })
            } else {
              this.value.itemDetails.quantity.items.forEach(item => {
                if (item.valueInBetween || item.widthError || item.heightError) {
                  reject()
                }
                this.value.subtotal_price += (+item.unit_price * +item.quantity)
                if (this.isValid(item)) {
                  this.NoF += +item.quantity
                }
                if (item.sub_items) {
                  item.sub_items.forEach(subItem => {
                    this.value.subtotal_price += (+subItem.unit_price * +subItem.quantity)
                  })
                }
              })
            }
            resolve(true)
          } else {
            if (this.value.itemDetails.offer_tab === 'Elevation') {
              let validItemsCount = 0;
              this.value.subtotal_price = 0
              this.NoF = 0
              this.value.itemDetails.elevations.forEach(elevation => {
                elevation.items.forEach(item => {
                  validItemsCount += this.isValid(item)
                  this.value.subtotal_price += (+item.unit_price * +item.quantity)
                  if (this.isValid(item)) {
                    this.NoF += +item.quantity
                  }
                  if (item.sub_items) {
                    item.sub_items.forEach(subItem => {
                      validItemsCount += this.isValid(subItem)
                      this.value.subtotal_price += (+subItem.unit_price * +subItem.quantity)
                    })
                  }
                })
              })
              if (!validItemsCount) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Action denied',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Please fill one elevation with one item at least'
                  },
                })
                reject();
              }
              resolve(true)
            } else {
              reject()
            }
          }
        })

      })
    },

    validationPaymentForm() {
      return new Promise((resolve, reject) => {
        this.$refs.paymentRules.validate().then(success => {
          if (success) {
            let totalPaymentTermsAmount = 0
            this.value.payments.forEach(item => {
              totalPaymentTermsAmount += +item.amount
            })
            if (totalPaymentTermsAmount !== 100) {
              this.$refs.stepFour.paymentTermsErrors.sumPaymentTermsError = 'Sum of all payment terms inputs must be 100%'
              reject()
            }
            let fourFormIsValid = true
            for (const property in this.$refs.stepFour.paymentTermsErrors) {
              if (this.$refs.stepFour.paymentTermsErrors[property]) {
                fourFormIsValid = false
              }
            }
            if (!fourFormIsValid) {
              reject()
            }
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            if (this.isValidNumber && this.isValidWhatsappNumber)
              resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    saveFormattedNumber(event) {
      this.isValidNumber = event.isValid
      this.value.customer.phone_number = event.formattedNumber
      this.phoneCountryCode = event.countryCode
      if (this.isSameNumber) {
        this.isValidWhatsappNumber = true
      }
    },

    changeCheck(checked) {
      if (checked) {
        this.isValidWhatsappNumber = true
      } else {
        this.value.customer.whatsapp_number = ''
        this.whatsappPhoneCountryCode = 'AE'
      }
    },

    saveFormattedWhatsAppNumber(event) {
      this.isValidWhatsappNumber = event.isValid
      this.value.customer.whatsapp_number = event.formattedNumber
    },

    setCustomer(customer) {
      this.selectFromCustomersList = true
      this.value.customer.phone_number = customer.phone_number;
      this.value.customer.whatsapp_number = customer.whatsapp_number;
      this.isSameNumber = customer.phone_number === customer.whatsapp_number;
      this.value.customer.first_name = customer.first_name;
      this.value.customer.last_name = customer.last_name;
      this.value.customer_id = customer.id;
      console.log(this.value)
      if (this.value.id) {
        this.autoSaveOfferService.autoSave(this.value.id, {customer_id: customer.id})
            .then(res => {
              console.log(res)
            })
      } else {
        this.submitting = true
        this.offerService.create({
          customer_id: customer.id,
          offer_tab: 'Elevation',
          discount: 0,
          discount_percentage: 1,
          project_date: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
          payments: [
            {
              name: "Advance Payment, Cash or Current Cheque",
              amount: 40
            },
            {
              name: "Progress Payment Before Delivery Glass and Aluminum",
              amount: 50,
              split: undefined
            },
            {
              name: "Paid Before Handing Over",
              amount: 5,
              split: undefined
            },
            {
              name: "Paid After Handing Over",
              amount: 5
            }
          ],
        }, {}, false).then(res => {
          console.log(res)
          this.value.id = res.id
          this.value.payments = res.payments
        }).finally(() => {
          this.submitting = false
        })
      }
      this.customers = [];
      setTimeout(() => {
        this.selectFromCustomersList = false
      }, 100)
    },

    storLocation() {
      return this.apiService.POST("locations", {
        name: this.$refs.stepTwo.locationName
      });
    },

    async submit() {
      if (await this.continueWithoutFullyFilledItem()) {
        let data = [];
        if (this.value.itemDetails.offer_tab === 'Elevation') {
          this.value.itemDetails.elevations.forEach(elements => {
            elements.items.forEach((element, index) => {
              if (this.isValid(element)) {
                data.push(this.mapPayload(elements.offer_elevation, element));
                if (element.sub_items) {
                  data[data.length - 1].sub_items = []
                  // console.log('data', data)
                  if (element.sub_items) {
                    element.sub_items.forEach(subItem => {
                      if (this.isValid(subItem)) {
                        data[data.length - 1].sub_items.push(this.mapPayload(elements.offer_elevation, subItem))
                      }
                    })
                  }
                }
              }
            })
          });
        } else {
          this.value.itemDetails.quantity.items.forEach(element => {
            data.push(this.mapPayload('Single', element))
            if (element.sub_items) {
              data[data.length - 1].sub_items = []
              element.sub_items.forEach(subItem => {
                data[data.length - 1].sub_items.push(this.mapPayload('Single', subItem))
              })
            }
          });
        }
        this.value.offer_tab = this.value.itemDetails.offer_tab
        this.value.items = data;
        this.$emit('formSubmitted');
      } else {
        this.showInvalidItemsListModal = true
      }
    },
    mapPayload(offer_elevation, element) {
      try {
        console.log('element', element)
        const item = {
          offer_elevation: offer_elevation,
          item_id: element.item_id,
          product_category_id: element.category_id,
          order: element.order,
          description_id: element.description_id,
          additional_option_ids: element.additional_option_ids,
          width: element.width,
          height: element.height,
          SQM: element.SQM,
          meter_price: element.meter_price,
          quantity: element.quantity,
          additional_options_cost: element.additional_options_cost,
          total_price: element.unit_price * element.quantity,
          unit_price: element.unit_price,
          glass_type_id: undefined,
          product_category_note: undefined,
          glass_detail_id: undefined,
          other_glass_detail: undefined,
          // hidden fields
          sand_blast: element.sand_blast,
          plan_location: element.plan_location,

        }
        if (element.withProductCategoryNote) {
          item.product_category_note = element.product_category_note
          element.glass_type_id = []
        }
        if (element.glass_type_id.length) {
          if (typeof element.glass_type_id[0] === 'number') {
            item.glass_type_id = element.glass_type_id[0]
          } else {
            item.other_glass_type = element.glass_type_id[0]
          }
        }
        if (element.withGlassDetails && element.glass_detail_id) {
          if (typeof element.glass_detail_id[0] === 'number') {
            item.glass_detail_id = element.glass_detail_id[0]
          } else {
            item.other_glass_detail = element.glass_detail_id[0]
          }
        }
        return item
      } catch (err) {
        console.error('mapPayload error: ', err)
      }
    },
    continueWithoutFullyFilledItem() {
      if (this.value.itemDetails.offer_tab === 'Elevation') {
        this.itemsNotValid = []
        this.value.itemDetails.elevations.forEach(elevation => {
          elevation.items.forEach(element => {
            console.log(element)
            if (!this.isValid(element) && element.formItemTouched) {
              this.itemsNotValid.push({...element, elevation_name: elevation.type})
            }
            console.log(element.sub_items)
            if (element.sub_items) {
              element.sub_items.forEach(subItemElement => {
                if (!this.isValid(subItemElement) && subItemElement.formItemTouched) {
                  this.itemsNotValid.push({...subItemElement, elevation_name: elevation.type})
                }
              });
            }
          })
        });
        console.log('this.itemsNotValid', this.itemsNotValid)
        if (this.itemsNotValid.length) {
          return this.$swal({
            title: 'Alert!',
            text: "There is some items are not fully filled, and will be discarded",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Discard',
            cancelButtonText: 'Return',
            customClass: {
              confirmButton: 'btn btn-outline-danger',
              cancelButton: 'btn btn-primary ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            // console.log(result)
            return result.isConfirmed
          })
        } else {
          return true
        }
      } else {
        let itemsNotValid = 0
        this.value.itemDetails.quantity.items.forEach(element => {
          // console.log(element)
          if (!this.isValid(element) && element.formItemTouched) {
            itemsNotValid++
          }
          if (element.sub_items) {
            element.sub_items.forEach(subItemElement => {
              // console.log(element)
              if (!this.isValid(subItemElement) && subItemElement.formItemTouched) {
                itemsNotValid++
              }
            });
          }
        });
        if (itemsNotValid > 0) {
          return this.$swal({
            title: 'Alert!',
            text: "There is some items are not fully filled, and will be discarded",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Discard',
            cancelButtonText: 'Return',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            // console.log(result)
            return result.isConfirmed
          })
        } else {
          return true
        }
      }
    }
  },

  beforeCreate() {
    if (!this.$store.hasModule('appOfferForm')) this.$store.registerModule('appOfferForm', offerStoreModule)
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.input-tel.is-focused .input-tel__input {
  box-shadow: unset !important;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.loader-overlay ~ div {
  filter: blur(3px);
}

.z-index-0 {
  z-index: 0;
}

@media (max-width: 576px) {
  .wizard-nav.wizard-nav-pills {
    position: sticky;
    top: 70px;
    z-index: 3;
    background: #fff;
  }

  .vue-form-wizard .wizard-navigation .wizard-nav li {
    padding-bottom: 8px;
  }
}

ul.number {
  padding-left: 0;
  list-style: none;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  width: 100%;
  border-right: 1px solid #d8d6de;
  border-bottom: 1px solid #d8d6de;
  border-left: 1px solid #d8d6de;
  border-radius: 0 0 8px 8px;
}

li.number {
  cursor: pointer;
  padding-left: 120px;

}
</style>
