<template>
  <div class="">
    <OfferForm ref="OfferForm" v-model="formData" @formSubmitted="publishOffer" :operation_type="'create'"/>
  </div>
</template>

<script>
import OfferForm from "@/views/components/seller/OfferForm";
import OfferService from "@/services/OfferService";
import AutoSaveOfferService from "@/services/AutoSaveOfferService";

export default {
  name: "CreateOffer",
  components: {
    OfferForm
  },
  data() {
    return {
      offerService: new OfferService(),
      autoSaveOfferService: new AutoSaveOfferService(),
      formData: {
        publish_status: 'Draft',
        project_name: '',
        estimated_cost: '',
        location_id: '',
        lead_source_type_id: '',
        measurement_type_id: '',
        files: [],
        filesThumbnail: [],
        customer: {
          first_name: '',
          last_name: '',
          phone_number: '',
          whatsapp_number: ''
        },
        subtotal_price: 0,
        discount: 0,
        discount_percentage: 1,
        project_date: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
        payments: [
          {
            name: "Advance Payment, Cash or Current Cheque",
            amount: undefined
          },
          {
            name: "Progress Payment Before Delivery Glass and Aluminum",
            amount: undefined,
            split: undefined
          },
          {
            name: "Paid Before Handing Over",
            amount: undefined,
            split: undefined
          },
          {
            name: "Paid After Handing Over",
            amount: undefined
          }
        ],
        itemDetails: {
          offer_tab: 'Elevation', // Elevation , Quantity
          elevations: [
            {
              offer_elevation: "Front Elevation",
              customTab: true,
              type: "Front Elevation",
              items: [
                {...this.$store.state.app.initItemObj}
              ],
            },
            {
              offer_elevation: "Back Elevation",
              customTab: true,
              type: "Back Elevation",
              items: [
                {...this.$store.state.app.initItemObj}
              ],
            },
            {
              offer_elevation: "Left Elevation",
              customTab: true,
              type: "Left Elevation",
              items: [
                {...this.$store.state.app.initItemObj}
              ],
            },
            {
              offer_elevation: "Right Elevation",
              customTab: true,
              type: "Right Elevation",
              items: [
                {...this.$store.state.app.initItemObj}
              ],
            },
            {
              offer_elevation: "Inside",
              customTab: true,
              type: "Inside",
              items: [
                {...this.$store.state.app.initItemObj}
              ],
            },
          ],
          quantity: {
            items: [
              {...this.$store.state.app.initItemObj}
            ]
          },
        },
      },
    }
  },
  methods: {
    publishOffer() {
      this.$refs.OfferForm.submitting = true;
      this.autoSaveOfferService.publishOffer(this.formData.id).then(res => {
        this.$router.push({name: 'all-offers'});
      }).finally(() => {
        this.$refs.OfferForm.submitting = false;
      })
    },
    storeOffer() {
      this.$refs.OfferForm.submitting = true;
      if (this.$refs.OfferForm.isSameNumber) {
        this.formData.customer.whatsapp_number = this.formData.customer.phone_number
      }
      const formKeysOnly = Object.assign({}, this.formData)
      // remove unused keys before sent request
      delete formKeysOnly.filesThumbnail
      if (this.formData.measurement_type_id === 3) {
        delete formKeysOnly.files
      }
      formKeysOnly.estimated_cost = +formKeysOnly.subtotal_price
      // formKeysOnly.estimated_duration = +formKeysOnly.subtotal_price > 120000 ? 60 : 45
      delete formKeysOnly.subtotal_price
      delete formKeysOnly.customer
      delete formKeysOnly.itemDetails
      this.offerService.create(formKeysOnly).then(async res => {
        // await this.$refs.OfferForm.successfullyApi();
        this.$router.push({name: 'all-offers'});
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.$refs.OfferForm.submitting = false;
      });
    },
  }
}
</script>

<style scoped>
</style>
