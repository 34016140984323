const formatterMixin = {
  methods: {
    onlyNumbers(event) {
      if (
        event.key === "e" ||
        event.key === "E" ||
        event.key === "+" ||
        event.key === "-" ||
        event.key === "ArrowDown" ||
        event.key === "ArrowUp"
      ) {
        event.preventDefault();
      }
    },
  },
};

export default formatterMixin;
