<template>
  <div
      :class="editMode ? 'editMode' : ''"
      class=""
  >
    <validation-provider
        #default="{ errors }"
        :name="`Can't be empty`"
        :rules="editMode ? 'required': ''"
    >
      <div v-if="!editMode">
        <span class="mr-1">{{ value }}</span>
        <feather-icon
            @click="openInput"
            icon="EditIcon"
            class="cursor-pointer"
        />
      </div>
      <div class="d-flex"
           v-if="editMode"
      >
        <b-form-input
            :plaintext="false"
            size="sm"
            class="w-50"
            autofocus
            @keydown="event=> event.key === ' ' ? localValue += ' ' : ''"
            @keypress.enter.prevent
            :state="errors.length > 0 ? false : null"
            v-model="localValue"/>
        <b-button
            size="sm"
            class="ml-1"
            variant="secondary"
            :disabled="errors.length > 0"
            @click="labelChanged(localValue)"
        >
          Save
        </b-button>
      </div>
      <small v-if="errors[0]" class="text-danger">Label can't be empty</small>
    </validation-provider>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "LabelInput",
  props: ['value'],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      localValue: '',
      editMode: false
    }
  },
  methods: {
    openInput() {
      this.editMode = true
      this.localValue = this.value
    },
    labelChanged(value) {
      this.$emit('update', value)
      this.editMode = false
    },
  },
  mounted() {
    this.localValue = this.value
  }
}
</script>

<style scoped>
/*::v-deep .nav-tabs .nav-link.active:has(.editMode) {*/
/*  background-color: #fff !important;*/
/*}*/

/*::v-deep .nav-tabs .nav-link.active:has(.editMode):after {*/
/*  background-color: #fff !important;*/
/*}*/
</style>
