<template>
    <b-modal id="customer-model" hide-footer size="xl" :centered="true">
        <b-container fluid>
            <template #modal-header />
            <customer-form ref="form" action="Save" v-model="formData" @formSubmitted="storeCustomer" />
        </b-container>
    </b-modal>
</template>

<script>
import { BButton, BModal, BContainer, VBModal, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CustomerForm from '../../customer/CustomerForm.vue'
import CustomerService from "@/services/CustomerService";

export default {
    name: "CustomerModel",
    components: {
        BButton,
        BContainer,
        BModal,
        BRow,
        BCol, CustomerForm
    },
    directives: {
        'b-modal': VBModal,
        Ripple
    },
    data() {
        return {
            formData: {
                updating: false
            },
            CustomerService: new CustomerService()
        }
    },
    props: {
        text: {
            default: "Are you sure you want to do this action ?",
            type: String
        },
    },
    methods: {
        open() {
            this.$bvModal.show('customer-model');
        },
        storeCustomer() {
            this.$refs.form.loading = true;
            this.CustomerService.create(this.formData).then(res => {
                this.$emit('result', res);
                this.$bvModal.hide('customer-model');
            }).catch(err => { }).finally(() => {
                this.$refs.form.loading = false;
            })
        },
    }
}
</script>

<style scoped>

</style>
