<template>
  <b-card>
    <div>
      <b-form ref="form" class="repeater-form" @submit.prevent>
        <!-- Row Loop -->
        <div v-for="(item, index) in value" :key="index+'-'+item.item_reference_code+'-'+item.item_id">
          <div ref="row" class="">
            <ItemForm
                ref="ItemForm"
                v-model="value[index]"
                :index="index"
                :refIndex="'ItemForm'+index"
                :offerId="offerId"
                :offer_elevation="offer_elevation"
                :categoryLoader="categoryLoader"
                :glassTypeLoader="glassTypeLoader"
                :categoryOptions="categoryOptions"
                :glassTypeOptions="glassTypeOptions"
                :glassTypeOptionsWithDetailsOptions="glassTypeOptionsWithDetailsOptions"
                :glassDetailsOptions="glassDetailsOptions"
                :additionalOptions="additionalOptions"
                :isSubItem="false"
                :canDeleteItem="value.length > 1"
                @addItem="addNewItemForm(index)"
                @addSubItem="addNewSubItemForm(index)"
                @removeItem="removeItem($event,index)"
            />
            <div class="">
              <div
                  v-for="(subItem,subIndex) in value[index].sub_items"
                  class=""
                  ref="subItemRow"
              >
                <div class="row">
                  <b-col :class=" index+1 !== value.length ? 'mt-2 mb-2':''" cols="12">
                    <hr>
                  </b-col>
                </div>
                <ItemForm
                    ref="SubItemForm"
                    v-model="value[index].sub_items[subIndex]"
                    :refIndex="'SubItemForm'+subIndex"
                    :index="subIndex"
                    :offerId="offerId"
                    :offer_elevation="offer_elevation"
                    :categoryLoader="categoryLoader"
                    :glassTypeLoader="glassTypeLoader"
                    :categoryOptions="categoryOptions"
                    :glassTypeOptions="glassTypeOptions"
                    :glassTypeOptionsWithDetailsOptions="glassTypeOptionsWithDetailsOptions"
                    :glassDetailsOptions="glassDetailsOptions"
                    :additionalOptions="additionalOptions"
                    :isSubItem="true"
                    @removeSubItem="removeSubItem($event,index,subIndex)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <b-col :class=" index+1 !== value.length ? 'mt-2 mb-2':''" cols="12">
              <hr>
            </b-col>
          </div>
        </div>
        <div v-if="!value.length">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mt-0 mt-md-2 mr-1"
              @click="addFirstItemForm()"
          >
                <span>
                  <feather-icon icon="PlusIcon" class="mr-25"/>
                <span>Add New Item</span>
                </span>
          </b-button>
        </div>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import {heightTransition} from '@core/mixins/ui/transition'
import DescriptionService from "@/services/DescriptionService";
import formatterMixin from '../formatter.js';
import ItemForm from "@/views/components/seller/OfferForm/steps/ItemForm";
import AutoSaveOfferService from "@/services/AutoSaveOfferService";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ItemForm,
  },
  directives: {
    Ripple,
  },
  mixins: [formatterMixin, heightTransition],
  props: [
    'value',
    'offerId',
    'offer_elevation',
    'categoryLoader',
    'glassTypeLoader',
    'glassTypeOptions',
    'glassTypeOptionsWithDetailsOptions',
    'glassDetailsOptions',
    'additionalOptions',
    'categoryOptions',
  ],
  data() {
    return {
      DescriptionService: new DescriptionService(),
      autoSaveOfferService: new AutoSaveOfferService(),
      descriptionLoader: false,
      priceFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  methods: {
    addFirstItemForm() {
      const firstItem = {...this.$store.state.app.initItemObj}
      firstItem.order = 1
      this.value.push(firstItem)
      setTimeout(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.$refs.ItemForm[0].$refs.formObserver.reset()
        this.$refs.row[0].scrollIntoView({behavior: 'smooth'})
      }, 100)
    },
    addNewItemForm(index) {
      console.log(index)
      const cloneItem = {...this.value[0]}
      console.log(cloneItem)
      console.log(this.$store.getters["appOfferForm/isPublished"])
      cloneItem.sub_items = []
      cloneItem.id = undefined
      cloneItem.description_id = undefined
      cloneItem.width = ''
      cloneItem.height = ''
      cloneItem.SQM = ''
      cloneItem.additional_option_ids = []
      cloneItem.meter_price = null
      cloneItem.additional_options_cost = 0
      cloneItem.quantity = 1
      cloneItem.order = this.value.length + 1
      this.value.splice(index + 1, 0, cloneItem)
      setTimeout(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.$refs.ItemForm[index + 1].$refs.formObserver.reset()
        this.$refs.row[index + 1].scrollIntoView({behavior: 'smooth'})
        if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
          let glass_type_id = undefined
          let other_glass_type = undefined
          let glass_detail_id = undefined
          let other_glass_detail = undefined
          if (cloneItem.glass_type_id) {
            if (typeof cloneItem.glass_type_id[0] === 'number') {
              glass_type_id = cloneItem.glass_type_id[0]
            } else {
              other_glass_type = cloneItem.glass_type_id[0]
            }
          }
          if (cloneItem.glass_detail_id) {
            if (typeof cloneItem.glass_detail_id[0] === 'number') {
              glass_detail_id = cloneItem.glass_detail_id[0]
            } else {
              other_glass_detail = cloneItem.glass_detail_id[0]
            }
          }
          const cloneItemIndex = index + 1
          // console.log('cloneItemIndex', cloneItemIndex)
          // console.log('this.$refs.ItemForm', this.$refs.ItemForm)
          // console.log('this.$refs.ItemForm[cloneItemIndex]', this.$refs.ItemForm[cloneItemIndex])
          this.$refs.ItemForm[cloneItemIndex].$data.creating = true
          this.autoSaveOfferService.createItem(this.offerId, {
            item: {
              width: cloneItem.width,
              height: cloneItem.height,
              SQM: cloneItem.SQM,
              offer_elevation: this.offer_elevation,
              product_category_id: cloneItem.category_id,
              item_id: cloneItem.item_id,
              description_id: cloneItem.description_id,
              glass_type_id: glass_type_id,
              other_glass_type: other_glass_type,
              product_category_note: cloneItem.product_category_note,
              glass_detail_id: glass_detail_id,
              other_glass_detail: other_glass_detail,
              quantity: cloneItem.quantity,
              order: cloneItem.order
            }
          }).then(res => {
            this.value[cloneItemIndex].id = res.id
            this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
          }).finally(() => {
            this.$refs.ItemForm[cloneItemIndex].$data.creating = false
          })
        }
        this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
      }, 100)
    },
    addNewSubItemForm(index) {
      try {
        const subItem = {...this.value[index]}
        subItem.sub_items = undefined
        // console.log('this.value', this.value)
        // console.log('this.value[index]', this.value[index])
        if (!this.value[index].sub_items) {
          this.value[index].sub_items = []
        }
        subItem.id = undefined
        subItem.parent_id = this.value[index].id
        subItem.width = ''
        subItem.height = ''
        subItem.SQM = ''
        subItem.glass_detail_id = []
        subItem.additional_option_ids = []
        subItem.meter_price = null
        subItem.additional_options_cost = 0
        subItem.quantity = 1
        subItem.order = this.value[index].sub_items.length + 1
        this.value[index].sub_items.push(subItem)
        setTimeout(() => {
          this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          const lastItemIndex = this.$refs.row[index].getElementsByClassName('itemCodeRef').length
          let elementPosition = this.$refs.row[index]
              .getElementsByClassName('itemCodeRef')[lastItemIndex - 1]
              .closest('.repeater-form').getBoundingClientRect().top
          // console.log(elementPosition)
          let offsetPosition = elementPosition + window.pageYOffset - 150;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
          if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
            let glass_type_id = undefined
            let other_glass_type = undefined
            if (typeof subItem.glass_type_id[0] === 'number') {
              glass_type_id = subItem.glass_type_id[0]
            } else {
              other_glass_type = subItem.glass_type_id[0]
            }
            let findComponentIndex = -1
            for (let i = 0; i <= index; i++) {
              findComponentIndex += this.value[i].sub_items.length
            }
            const subItemIndex = this.value[index].sub_items.length - 1
            this.$refs.SubItemForm[findComponentIndex].$data.creating = true
            this.autoSaveOfferService.createItem(this.offerId, {
              item: {
                parent_id: subItem.parent_id,
                width: subItem.width,
                height: subItem.height,
                SQM: subItem.SQM,
                offer_elevation: this.offer_elevation,
                product_category_id: subItem.category_id,
                item_id: subItem.item_id,
                description_id: subItem.description_id,
                glass_type_id: glass_type_id,
                product_category_note: subItem.product_category_note,
                other_glass_type: other_glass_type,
                quantity: subItem.quantity,
                order: subItem.order
              }
            }).then(res => {
              this.value[index].sub_items[subItemIndex].id = res.id
            }).finally(() => {
              this.$refs.SubItemForm[findComponentIndex].$data.creating = false
            })
          }
        }, 100)
      } catch (err) {
        console.error('addNewSubItemForm', err)
      }
    },
    removeItem(itemId, index) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        if (itemId) {
          this.$refs.ItemForm[index].$data.deleting = true
          this.autoSaveOfferService.deleteItem(itemId)
              .then(res => {
                console.log(res)
                this.value.splice(index, 1)
                this.trTrimHeight(this.$refs.ItemForm[0].offsetHeight)
                this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
              })
              .finally(() => {
                if (this.$refs.ItemForm[index])
                  this.$refs.ItemForm[index].$data.deleting = false
              })
        } else {
          this.value.splice(index, 1)
          this.trTrimHeight(this.$refs.ItemForm[0].offsetHeight)
          this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
        }
      } else {
        this.value.splice(index, 1)
        this.trTrimHeight(this.$refs.ItemForm[0].offsetHeight)
        this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
      }
    },
    removeSubItem(itemId, index, subIndex) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        if (itemId) {
          console.log('index',index,'subIndex',subIndex)
          let findComponentIndex = -1
          for (let i = 0; i <= index; i++) {
            if (i === index) {
              findComponentIndex += subIndex + 1
            } else {
              findComponentIndex += this.value[i].sub_items.length
            }
            // console.log('findComponentIndex',findComponentIndex)
          }
          this.$refs.SubItemForm[findComponentIndex].$data.deleting = true
          this.autoSaveOfferService.deleteItem(itemId)
              .then(res => {
                console.log(res)
                this.value[index].sub_items.splice(subIndex, 1)
                this.trTrimHeight(this.$refs.ItemForm[0].offsetHeight)
                this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
              })
              .finally(() => {
                if (this.$refs.SubItemForm[findComponentIndex])
                  this.$refs.SubItemForm[findComponentIndex].$data.deleting = false
              })
        } else {
          this.value[index].sub_items.splice(subIndex, 1)
          this.trTrimHeight(this.$refs.ItemForm[0].offsetHeight)
          this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
        }
      } else {
        this.value[index].sub_items.splice(subIndex, 1)
        this.trTrimHeight(this.$refs.ItemForm[0].offsetHeight)
        this.$store.dispatch('appOfferForm/changeItemSelectedValue', true)
      }
    },

    initTrHeight() {
      this.trSetHeight(null)
    },
  },
  mounted() {
    this.initTrHeight()
    if (this.$refs.ItemForm) {
      this.$refs.ItemForm.forEach(itemForm => {
        itemForm.fillData()
      })
    }
    if (this.$refs.SubItemForm)
      this.$refs.SubItemForm.forEach(itemForm => {
        itemForm.fillData()
      })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables/variables";

.repeater-form {
  //overflow: hidden;
  transition: .35s height;
}

.calc-price-style {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

::v-deep .vs__selected-options {
  overflow: hidden;
}

#glass-type ::v-deep .vs__selected-options, #additional-options ::v-deep .vs__selected-options {
  flex-wrap: nowrap;
}

#glass-type ::v-deep .vs__selected, #additional-options ::v-deep .vs__selected {
  z-index: 10;
  padding-right: 20px;
  position: relative;
}

#glass-type ::v-deep .vs__selected button, #additional-options ::v-deep .vs__selected button {
  position: absolute;
  right: 0;
  background-color: $primary;
  width: 16px;
  height: 100%;
  place-content: center;

}
</style>
