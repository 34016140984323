var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Project Details ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter Your Project Details. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Project Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"project name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","debounce":1000,"state":errors.length > 0 ? false : null,"placeholder":"Project name"},on:{"update":function($event){return _vm.saveChange($event,'project_name')}},model:{value:(_vm.value.project_name),callback:function ($$v) {_vm.$set(_vm.value, "project_name", $$v)},expression:"value.project_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Project Date","label-for":"project-date"}},[_c('validation-provider',{attrs:{"name":"project date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.checkDateField())?_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"project_date","placeholder":"ex: 2023-01-20","state":errors.length > 0 ? false : null,"readonly":true},on:{"on-change":function($event){return _vm.saveChange(_vm.value.project_date,'project_date')}},model:{value:(_vm.value.project_date),callback:function ($$v) {_vm.$set(_vm.value, "project_date", $$v)},expression:"value.project_date"}}):_c('b-form-input',{attrs:{"id":"project-date","readonly":"","state":errors.length > 0 ? false : null,"placeholder":"Project Date"},model:{value:(_vm.value.project_date),callback:function ($$v) {_vm.$set(_vm.value, "project_date", $$v)},expression:"value.project_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Project Location","label-for":"language"}},[_c('validation-provider',{attrs:{"name":"Project location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"language","loading":_vm.locationLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"taggable":_vm.value.withCustomGlassTypeSelect!==0,"multiple":"","create-option":function (tag) { return ({id:tag, name: tag }); },"options":_vm.locationOptions,"placeholder":"Select location","reduce":function (option) { return option.id; },"label":"name"},on:{"input":function($event){return _vm.limitSelected($event, 'location_id')}},model:{value:(_vm.value.location_id),callback:function ($$v) {_vm.$set(_vm.value, "location_id", $$v)},expression:"value.location_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"order-md-1 order-2",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Lead Source","label-for":"lead-source"}},[_c('validation-provider',{attrs:{"name":"lead source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"lead-source","loading":_vm.sourceLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.sourceOptions,"placeholder":"Select lead source","reduce":function (option) { return option.id; },"label":"name"},on:{"input":function($event){return _vm.saveChange($event,'lead_source_type_id')}},model:{value:(_vm.value.lead_source_type_id),callback:function ($$v) {_vm.$set(_vm.value, "lead_source_type_id", $$v)},expression:"value.lead_source_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"order-md-3 order-3",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Project Measurements","label-for":"project-measurements"}},[_c('validation-provider',{attrs:{"name":"project measurements","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"project-measurements","loading":_vm.measurementLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.measurementOptions,"placeholder":"Select project measurements","reduce":function (option) { return option.id; },"label":"name"},on:{"input":function($event){return _vm.saveChange($event,'measurement_type_id')}},model:{value:(_vm.value.measurement_type_id),callback:function ($$v) {_vm.$set(_vm.value, "measurement_type_id", $$v)},expression:"value.measurement_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.showAttachmentsInput)?_c('b-col',{staticClass:"order-md-4 order-4",attrs:{"md":"6"}},[_c('DropZone',{attrs:{"label":'Select project measurement file',"filesThumbnail":_vm.value.filesThumbnail,"filesIDs":_vm.value.files,"query-param":'path=offer',"maxSize":30},on:{"dataChanged":function($event){return _vm.filesChanged($event)}}}),_c('validation-provider',{attrs:{"name":"project measurement file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-none",attrs:{"id":"","state":errors.length > 0 ? false:null,"placeholder":"project measurement file"},model:{value:(_vm.filesInput),callback:function ($$v) {_vm.filesInput=$$v},expression:"filesInput"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2428643525)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }