export default {
    namespaced: true,
    state: {
        itemSelected: false,
        offerStatus: null
    },
    getters: {
        itemSelected: state => state.itemSelected,
        isPublished: state => state.offerStatus,
    },
    mutations: {},
    actions: {
        changeItemSelectedValue({state}, payload) {
            console.log('payload', payload)
            state.itemSelected = payload
        },
        offerStatus({state}, payload) {
            console.log('payload', payload)
            state.offerStatus = payload
        }
    },
}
