import CrudService from "@/services/shared/http/CrudService";

export default class AutoSaveOfferService extends CrudService {
    constructor(params = {}) {
        super('save-offer', params);
    }

    // customer_id , project details data , item form , payment terms data
    autoSave(offerId, data) {
        return this.api.PUT('save-offer/' + offerId, data, {}, false)
    }

    // for creating item and sub-item
    createItem(offerId, data) {
        return this.api.POST('save-offer/' + offerId, data, {}, false)

    }

    deleteItem(itemId, data) {
        return this.api.DELETE('delete-offer-item/' + itemId, {}, false)

    }

    changeElevationName(data) {
        return this.api.PUT('update-items-elevation', data, {}, false)
    }

    createSplitPayment(offerId, data) {
        return this.api.PUT('save-offer/' + offerId, data, {}, false)

    }

    deleteSplitPayment(offerPaymentId) {
        return this.api.DELETE('delete-offer-payment/' + offerPaymentId, {}, false)

    }

    publishOffer(id) {
        return this.api.POST('publish-offer/' + id, {})

    }
}
