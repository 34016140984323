<template>
  <b-card title="Select Items">
    <b-form-group
        class="mb-2" label="Offer type" label-for="">
      <b-form-radio-group
          v-model="value.offer_tab"
          :options="options"
          class=""
          @change="autoSave($event,'offer_tab')"
          name="offer_type"
      />
    </b-form-group>
    <b-tabs v-if="value.offer_tab === 'Elevation'"
            nav-class="nav-right"
            no-key-nav
            nav-wrapper-class="tabs-border">
      <b-tab
          v-for="(elevation, index) in value.elevations"
          :key="index"
          :title="elevation.type"
          @click="changeIndex(index)"
          :active="index == step"
          lazy
          :title-link-class="elevation.customTab && customTab.editMode  ? 'bg-transparent' : ''"
      >
        <template v-if="elevation.customTab" #title>
          <LabelInput
              :value="elevation.type"
              @update="tabNameChanged($event ,elevation,index)"
          />
        </template>
        <!-- <validation-observer :ref="`form_${index}`" tag="form"> -->
        <form-repeat
            v-if="categoryOptions && additionalOptions"
            v-model="elevation.items"
            :offerId="offerId"
            :offer_elevation="elevation.offer_elevation"
            :categoryLoader="categoryLoader"
            :glassTypeLoader="glassTypeLoader"
            :categoryOptions="categoryOptions"
            :glassTypeOptions="glassTypeOptions"
            :glassTypeOptionsWithDetailsOptions="glassTypeOptionsWithDetailsOptions"
            :glassDetailsOptions="glassDetailsOptions"
            :additionalOptions="additionalOptions"
            ref="formRepeat"
        />
        <!-- </validation-observer> -->
      </b-tab>
      <template #tabs-end>
        <b-nav-item
            role="presentation"
            href="#"
            @click.prevent="newTab(value.elevations.length +1)"
        >
          <strong>Add New Elevation +</strong>
        </b-nav-item>
      </template>
      <div>
        <b-button @click="stepChange(-1)" v-show="step>0">
          <feather-icon icon="ArrowLeftIcon"/>
          Previous
        </b-button>
        <b-button @click="stepChange(1)" v-show="step< value.elevations.length -1" style="float: right;"
                  variant="primary">
          Next
          <feather-icon icon="ArrowRightIcon"/>
        </b-button>
      </div>
    </b-tabs>
    <div v-else class="">
      <form-repeat
          v-if="categoryOptions && additionalOptions"
          v-model="value.quantity.items"
          :offerId="offerId"
          :offer_elevation="'Single'"
          :categoryLoader="categoryLoader"
          :glassTypeLoader="glassTypeLoader"
          :categoryOptions="categoryOptions"
          :glassTypeOptions="glassTypeOptions"
          :glassTypeOptionsWithDetailsOptions="glassTypeOptionsWithDetailsOptions"
          :glassDetailsOptions="glassDetailsOptions"
          :additionalOptions="additionalOptions"
          ref="formRepeat"
      />
    </div>
  </b-card>
</template>

<script>
import FormRepeat from './FormRepeat.vue';
import ProductService from "@/services/ProductService";
import ApiService from "@/services/shared/http/ApiService";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import LabelInput from "@/views/components/seller/OfferForm/LabelInput";
import AutoSaveOfferService from "@/services/AutoSaveOfferService";
import AdditionService from "@/services/AdditionService";


export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    FormRepeat,
    LabelInput,
  },
  props: ['value', 'offerId'],
  data() {
    return {
      ApiService: new ApiService(),
      AdditionService: new AdditionService(),
      ProductService: new ProductService(),
      autoSaveOfferService: new AutoSaveOfferService(),
      categoryLoader: true,
      glassTypeLoader: true,
      categoryOptions: undefined,
      glassTypeOptions: [],
      glassTypeOptionsWithDetailsOptions: [],
      glassDetailsOptions: [],
      additionalOptions: undefined,
      customTab: {
        editMode: false,
        title: '',
      },
      step: 0,
      options: [
        {text: 'Elevation', value: 'Elevation'},
        {text: 'Quantity', value: 'Quantity'}
      ],
    }
  },
  methods: {
    changeIndex(index) {
      this.step = index
      setTimeout(() => {
        this.$refs?.formRepeat[0].$refs.ItemForm.forEach(item => {
          item.$refs.formObserver.validate().then(res => {
          })
        })
        if (this.$refs?.formRepeat[0].$refs.SubItemForm) {
          this.$refs?.formRepeat[0].$refs.SubItemForm.forEach(item => {
            item.$refs.formObserver.validate().then(res => {

            })
          })
        }
      })
    },
    newTab(index) {
      const newTabObj = {
        offer_elevation: 'Others',
        customTab: true,
        type: 'Others',
      }
      newTabObj.items = [{...this.$store.state.app.initItemObj}]
      console.log("new tab items")
      console.log(newTabObj.items)
      this.value.elevations.push(newTabObj)
    },
    tabNameChanged(value, elevation, eleIndex) {
      elevation.offer_elevation = value
      elevation.type = value
      if (elevation.items.length > 1 || elevation.items[0].id) {
        this.changeItemsElevationName(value, eleIndex)
      }
    },
    changeItemsElevationName(value, eleIndex) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        const itemsIds = this.getItemIds(this.value.elevations[eleIndex].items)
        this.autoSaveOfferService.changeElevationName({
          name: value,
          offer_item_ids: itemsIds
        })
      }
    },
    getItemIds(arr) {
      let itemIds = [];

      // Loop through the array
      try {
        for (let obj of arr) {
          if (obj.id) {
            itemIds.push(obj.id);
          }
          // Check if the object has a sub_items
          if (obj.sub_items) {
            // If it does, get the id values for the sub_items
            itemIds = itemIds.concat(this.getItemIds(obj.sub_items));
          }
        }
      } catch (err) {
        console.error(err)
      }

      return itemIds;
    },
    getCategoryOptions() {
      this.categoryLoader = true;
      this.ProductService.index().then(res => {
        this.categoryOptions = res.data;
      }).catch(err => {
      }).finally(() => {
        this.categoryLoader = false;
      })
    },

    getGlassTypeOptions() {
      this.glassTypeLoader = true;
      this.ApiService.GET('glass-types').then((res) => {
        this.glassTypeOptions = res.data;
      }).catch().finally(() => {
        this.glassTypeLoader = false;
      });
    },
    getGlassTypeOptionsWithDetails() {
      this.ApiService.GET('glass-types?custom_select=true').then((res) => {
        this.glassTypeOptionsWithDetailsOptions = res.data;
      })
    },

    getAdditionalOptions() {
      this.AdditionService.index({per_page: 200, status: true}).then((res) => {
        this.additionalOptions = res.data;
      });
    },
    getGlassDetails() {
      this.ApiService.GET('glass-details').then((res) => {
        this.glassDetailsOptions = res.data;
      });
    },
    setRefCode() {
      // console.log('this.value', this.value)
      const refCodes = {}
      if (this.value.offer_tab === 'Elevation') {
        try {
          this.value.elevations.forEach((ele, eleIndex) => {
            // console.log(eleIndex, ele)
            ele.items.forEach((item, itemIndex) => {
              if (item.reference_code?.name) {
                if (refCodes[item.reference_code.name]) {
                  refCodes[item.reference_code.name].count++
                } else {
                  refCodes[item.reference_code.name] = {count: 0, children: 0}
                  refCodes[item.reference_code.name].count++
                }
                item.item_reference_code = item.reference_code.name + '-' + refCodes[item.reference_code.name].count
                if (item.sub_items) {
                  if (!refCodes[item.reference_code.name].children) {
                    refCodes[item.reference_code.name].children = 0
                  }
                  item.sub_items.forEach((subItem, subIndex) => {
                    if (subItem.reference_code.name) {
                      if (refCodes[subItem.reference_code.name]) {
                        refCodes[subItem.reference_code.name].children++
                      } else {
                        refCodes[subItem.reference_code.name] = {count: 0, children: 0}
                        refCodes[subItem.reference_code.name].children++
                      }
                      subItem.item_reference_code =
                          item.reference_code.name + '-'
                          + refCodes[item.reference_code.name].count + '-'
                      // console.log('item.reference_code.name', item.reference_code.name)
                      // console.log('subItem.reference_code.name', subItem.reference_code.name)
                      if (item.reference_code.name !== subItem.reference_code.name) {
                        subItem.item_reference_code += subItem.reference_code.name + '-'
                      }
                      subItem.item_reference_code += refCodes[subItem.reference_code.name].children
                    }
                  })
                }
              }
              // console.log('\t\t' + itemIndex, item)
            })
          })

        } catch (error) {
          console.error('setRefCode in elevation', error)
        }
        // console.log('RefCode in elevation', refCodes)
      } else {
        try {
          this.value.quantity.items.forEach((item, itemIndex) => {
            if (item.reference_code?.name) {
              if (refCodes[item.reference_code.name]) {
                refCodes[item.reference_code.name].count++
              } else {
                refCodes[item.reference_code.name] = {count: 0, children: 0}
                refCodes[item.reference_code.name].count++
              }
              item.item_reference_code = item.reference_code.name + '-' + refCodes[item.reference_code.name].count
              if (item.sub_items) {
                if (!refCodes[item.reference_code.name].children) {
                  refCodes[item.reference_code.name].children = 0
                }
                item.sub_items.forEach((subItem, subIndex) => {
                  if (subItem.reference_code.name) {
                    if (refCodes[subItem.reference_code.name]) {
                      refCodes[subItem.reference_code.name].children++
                    } else {
                      refCodes[subItem.reference_code.name] = {count: 0, children: 0}
                      refCodes[subItem.reference_code.name].children++
                    }
                    subItem.item_reference_code =
                        item.reference_code.name + '-'
                        + refCodes[item.reference_code.name].count + '-'
                    if (item.reference_code.name !== subItem.reference_code.name) {
                      subItem.item_reference_code += subItem.reference_code.name + '-'
                    }
                    subItem.item_reference_code += refCodes[subItem.reference_code.name].children
                  }
                })
              }
            }
            // console.log('\t\t' + itemIndex, item)
          })
        } catch (err) {
          console.error('setRefCode in quantity', err)
        }
        // console.log('RefCode in quantity', refCodes)
      }
    },
    async stepChange(value) {
      if (this.step + value == -1 || this.step + value == 6) return;
      this.step += value;
    },
    autoSave(value, key) {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this.autoSaveOfferService.autoSave(this.offerId, {[key]: value})
      }
    }
  },
  mounted() {
    this.getCategoryOptions();
    this.getGlassTypeOptions();
    this.getGlassTypeOptionsWithDetails();
    this.getAdditionalOptions();
    this.getGlassDetails();
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

::v-deep .bg-transparent:after {
  height: 0;
}

</style>
