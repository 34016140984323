<template>
  <b-row>
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">
        Project Details
      </h5>
      <small class="text-muted">
        Enter Your Project Details.
      </small>
    </b-col>
    <b-col md="6">
      <b-form-group label="Project Name" label-for="name">
        <validation-provider #default="{ errors }" name="project name" rules="required">
          <b-form-input id="name"
                        v-model="value.project_name"
                        :debounce="1000"
                        @update="saveChange($event,'project_name')"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Project name"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group label="Project Date" label-for="project-date">
        <validation-provider #default="{ errors }" name="project date">
          <flat-pickr
              v-if="checkDateField()"
              v-model="value.project_date"
              id="project_date"
              placeholder="ex: 2023-01-20"
              class="form-control"
              :state="errors.length > 0 ? false : null"
              :readonly="true"
              @on-change="saveChange(value.project_date,'project_date')"
          />
          <b-form-input
              v-else
              id="project-date" readonly="" v-model="value.project_date"
              :state="errors.length > 0 ? false : null" placeholder="Project Date"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group label="Project Location" label-for="language">
        <validation-provider #default="{ errors }" name="Project location" rules="required">
          <v-select id="language" class="custom-select-style"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" :loading="locationLoader"
                    v-model="value.location_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :taggable="value.withCustomGlassTypeSelect!==0"
                    multiple
                    :create-option="tag => ({id:tag, name: tag })"
                    @input="limitSelected($event, 'location_id')"
                    :options="locationOptions" placeholder="Select location" :reduce="option => option.id"
                    label="name"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <!--    <b-col md="6" v-show="value.location_id == 0">-->
    <!--      <b-form-group label="Location Name" label-for="location-name">-->
    <!--        <validation-provider #default="{ errors }" name="location-name">-->
    <!--          <b-form-input id="location-name" v-model="locationName" placeholder="Location Name"-->
    <!--                        :state="errors.length > 0 ? false : null"/>-->
    <!--          <small class="text-danger">{{ errors[0] }}</small>-->
    <!--        </validation-provider>-->
    <!--      </b-form-group>-->
    <!--    </b-col>-->
    <b-col md="6" class="order-md-1 order-2">
      <b-form-group label="Lead Source" label-for="lead-source">
        <validation-provider #default="{ errors }" name="lead source" rules="required">
          <v-select id="lead-source" class="custom-select-style"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" :loading="sourceLoader"
                    v-model="value.lead_source_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    @input="saveChange($event,'lead_source_type_id')"
                    :options="sourceOptions" placeholder="Select lead source" :reduce="option => option.id"
                    label="name"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6" class="order-md-3 order-3">
      <b-form-group label="Project Measurements" label-for="project-measurements">
        <validation-provider #default="{ errors }" name="project measurements" rules="required">
          <v-select id="project-measurements" class="custom-select-style"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" :loading="measurementLoader"
                    v-model="value.measurement_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    @input="saveChange($event,'measurement_type_id')"
                    :options="measurementOptions" placeholder="Select project measurements"
                    :reduce="option => option.id" label="name"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col class="order-md-4 order-4" v-if="showAttachmentsInput" md="6">
      <DropZone
          :label="'Select project measurement file'"
          :filesThumbnail="value.filesThumbnail"
          :filesIDs="value.files"
          :query-param="'path=offer'"
          :maxSize="30"
          @dataChanged="filesChanged($event)"
      />
      <validation-provider #default="{ errors }" name="project measurement file" rules="required">
        <b-form-input class="d-none" id="" v-model="filesInput" :state="errors.length > 0 ? false:null"
                      placeholder="project measurement file"/>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
  </b-row>
</template>
<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {BRow, BCol, BFormGroup, BFormInput} from 'bootstrap-vue'
import ApiService from "@/services/shared/http/ApiService";
import vSelect from 'vue-select'
import DropZone from "@/views/components/file-uploader/DropZone";
import flatPickr from 'vue-flatpickr-component'
import AutoSaveOfferService from "@/services/AutoSaveOfferService";

export default {
  name: "step-two",
  props: ['value'],
  components: {
    ValidationProvider, ValidationObserver, vSelect,
    BRow, BCol, BFormGroup, BFormInput, DropZone, flatPickr
  },
  data() {
    return {
      apiService: new ApiService(),
      autoSaveOfferService: new AutoSaveOfferService(),
      measurementOptions: [],
      measurementLoader: true,
      sourceOptions: [],
      sourceLoader: true,
      locationOptions: [],
      locationLoader: true,
      locationName: undefined,
      userData: JSON.parse(localStorage.getItem("userData"))
    }
  },
  computed: {
    filesInput() {
      return this.value.files?.length ? 'dummy text' : ''
    },
    showAttachmentsInput() {
      // maybe later
      return this.value.measurement_type_id !== 3 && this.value.measurement_type_id !== ''
    }
  },
  methods: {
    getMeasurementOptions() {
      this.measurementLoader = true
      this.apiService.GET('measurement-types').then(res => {
        this.measurementOptions = res.data
      }).catch((error) => {
      }).finally(() => {
        this.measurementLoader = false;
      });
    },
    getSourceOptions() {
      this.sourceLoader = true
      this.apiService.GET('lead-source-types').then(res => {
        this.sourceOptions = res.data
      }).catch(() => {
      }).finally(() => {
        this.sourceLoader = false
      })
    },
    getLocationOptions() {
      this.locationLoader = true
      this.apiService.GET('locations').then(res => {
        this.locationOptions = res.data;
      }).catch((error) => {
      }).finally(() => {
        this.locationLoader = false
      })
    },
    checkDateField() {
      console.log(this.value.id)
      return this.userData.roles[0].name === "Super_Admin"
    },
    limitSelected(e, key = undefined) {
      console.log(e)
      if (e.length > 1) {
        e.shift()
      }
      if (key && e.length) {
        if (this.value.publish_status === 'Draft') {
          if (typeof this.value[key][0] === 'number') {
            this.autoSaveOfferService.autoSave(this.value.id, {
              location_id: e[0],
              other_location: null
            })
          } else {
            this.autoSaveOfferService.autoSave(this.value.id, {
              location_id: null,
              other_location: e[0]
            })
          }
        }
      } else {
        this.autoSaveOfferService.autoSave(this.value.id, {
          location_id: null,
          other_location: null
        })
      }

    },
    filesChanged(files) {
      this.value.files = files
      this.saveChange(files, 'files')
    },
    saveChange(value, key) {
      if (this.value.publish_status === 'Draft') {
        console.log(value)
        this.autoSaveOfferService.autoSave(this.value.id, {[key]: value})
      }
    }
  },
  mounted() {
    this.getMeasurementOptions();
    this.getLocationOptions();
    this.getSourceOptions();
  }
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
